export const getPWADisplayMode = () => {
  const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
  const { search } = window.location;

  if (
    search.includes('?twa') ||
    document.referrer.startsWith('android-app://')
  ) {
    return 'twa';
  }

  if (search.includes('?pwa')) {
    return 'pwa';
  }

  if (navigator.standalone || isStandalone) {
    return 'standalone';
  }

  return 'browser';
};
