import HttpsProxyAgent from 'https-proxy-agent';
import fetch from 'node-fetch';

import { config } from 'config';

import {
  API_ENDPOINT_HUMAN_DESIGN_GEO,
  API_ENDPOINT_HUMAN_DESIGN,
} from 'config/constants/humanDesign';

import { safeGet } from 'utils/safeGet';
import { promiseTimeout } from 'utils/promiseTimeout';
import { INTERNAL_SERVER_ERROR_STATUS } from 'config/constants/statusCodes';

interface FetchOptions {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE';
  headers: {
    'x-chain-id': string;
    accept?: string;
    'content-type'?: string;
    'x-rsid'?: string;
    'x-ruid'?: string;
    'x-user-data'?: string;
    'x-splits'?: string;
    'x-request-id'?: string;
  };
  body?: string;
  rejectUnauthorized?: boolean;
  agent?: any;
}

interface CSRConfig {
  apiUrl: string;
  apiTimeoutClient: number;
}

export const request = async (
  path: string,
  opts: FetchOptions,
  csrConfig: CSRConfig,
  apiVersion: 'v3' | 'v4' | 'geo' | 'hd' | 'location',
  // eslint-disable-next-line max-params
) => {
  const apiTimeout = __SERVER__
    ? config.API_TIMEOUT
    : csrConfig.apiTimeoutClient;
  let apiUrl = `${__SERVER__ ? config.API_URL : csrConfig.apiUrl}${apiVersion}/`;

  if (apiVersion === 'geo') {
    apiUrl = API_ENDPOINT_HUMAN_DESIGN_GEO;
  } else if (apiVersion === 'hd') {
    apiUrl = API_ENDPOINT_HUMAN_DESIGN;
  }

  const isMetric = path === '/ts-metrics/';

  const url = isMetric ? path : `${apiUrl}${path}`;

  try {
    const res = await promiseTimeout(apiTimeout || 0, fetch(url, opts), url);

    if (res.ok === false) {
      const err = new Error(`Не удалось получить данные запроса: ${url}`);

      // @ts-expect-error: Property 'status' does not exist on type 'Error'
      err.status = res.status;
      throw err;
    }

    if (opts.method === 'DELETE' || isMetric) {
      return {};
    }

    const data = await res.json();

    return data;
  } catch (error) {
    // TODO(HORO-0): logger
    console.error(`${new Date()} ${error}`, opts);

    return {
      error: (error && error.message) || `Ошибка запроса: ${url}`,
      status: safeGet(() => error.status, INTERNAL_SERVER_ERROR_STATUS),
    };
  }
};

// eslint-disable-next-line import/no-anonymous-default-export, import/no-default-export
export default async ({
  path,
  method = 'GET',
  state,
  body,
  additionalHeaders,
  apiVersion,
}: {
  path: string;
  method?: FetchOptions['method'];
  state: IAppState;
  body?: object | [] | string;
  additionalHeaders?: {
    userData: object;
  };
  apiVersion?: 'v3' | 'v4' | 'geo' | 'hd';
}): Promise<any> => {
  let data = null;

  const {
    ramblerId,
    rsid,
    ruid,
    requestId,
    config: { apiUrl, rejectUnauthorized, httpsProxy, apiTimeoutClient },
    splits,
  } = state.runtime;

  const csrConfig: CSRConfig = { apiUrl, apiTimeoutClient };

  const opts: FetchOptions = {
    method,
    headers: {
      'x-chain-id': ramblerId || '',
      'x-rsid': rsid,
      'x-ruid': ruid,
      'x-splits': JSON.stringify(splits),
      'x-request-id': requestId,
    },
  };

  if (additionalHeaders && additionalHeaders.userData) {
    opts.headers['x-user-data'] = JSON.stringify(additionalHeaders.userData);
  }

  if (method === 'POST' || method === 'PUT' || method === 'DELETE') {
    opts.headers['content-type'] = 'application/json';
    opts.body = typeof body === 'string' ? body : JSON.stringify(body);
  }

  if (
    __SERVER__ &&
    (apiVersion === 'hd' || apiVersion === 'geo') &&
    httpsProxy
  ) {
    opts.rejectUnauthorized = rejectUnauthorized;
    opts.agent = new (HttpsProxyAgent as any)(httpsProxy);
  }

  data = await request(path, opts, csrConfig, apiVersion || 'v3');

  return data;
};
