/* eslint-disable @typescript-eslint/no-magic-numbers */
import _cloneDeep from 'lodash.clonedeep';
import _isNumber from 'lodash.isnumber';

import {
  SUBSTRATES,
  STARS_COUNT,
  STEPS,
  SYMBOLS_HEIGHTS,
  SYMBOLS_ROTATION,
  SYMBOLS_POSITIONS,
} from 'config/constants/fortune';

type TimeType = 'past' | 'present' | 'future';
type DeviceType = 'mobile' | 'desktop';
type SubstrateType = 'circle' | 'side';
type SymbolsTypes = 'circle' | 'side' | 'symbols';
type SizeType = 'big' | 'small';
type StepType = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
type CircleType = 0 | 1 | 2;

interface SymbolType {
  circle: CircleType;
  side: 0;
  symbols: number;
}

export interface CoffeeLogicTypes {
  step: StepType;
  usedSymbols: Record<
    TimeType,
    {
      circle?: (typeof SUBSTRATES)['circle'];
      side?: (typeof SUBSTRATES)['side'];
      symbols: number[];
      heights: number[];
      rotations: number[];
      positions: {
        top?: string;
        right?: string;
        bottom?: string;
        left?: string;
      }[];
    }
  >;
  selectedSymbols: {
    symbols: number[];
    circle: (0 | 1 | 2)[];
    side: 0[];
    past: number | null;
    present: number | null;
    future: number | null;
  };
  symbols: ICoffeeGame[];
  type: 'desktop' | 'mobile';
}

const initialData: {
  symbols: [];
  usedSymbols: CoffeeLogicTypes['usedSymbols'];
  selectedSymbols: CoffeeLogicTypes['selectedSymbols'];
} = {
  symbols: [],
  usedSymbols: {
    past: {
      circle: [],
      symbols: [],
      heights: [],
      rotations: [],
      positions: [],
    },
    present: {
      circle: [],
      symbols: [],
      heights: [],
      rotations: [],
      positions: [],
    },
    future: {
      side: [],
      symbols: [],
      heights: [],
      rotations: [],
      positions: [],
    },
  },
  selectedSymbols: {
    symbols: [],
    circle: [],
    side: [],
    past: null,
    present: null,
    future: null,
  },
};

/**
 * Класс, описывающий логику работы гадания по кофейной гуще
 */
export class CoffeeLogic implements CoffeeLogicTypes {
  step: CoffeeLogicTypes['step'];
  usedSymbols: CoffeeLogicTypes['usedSymbols'];
  selectedSymbols: CoffeeLogicTypes['selectedSymbols'];
  symbols: CoffeeLogicTypes['symbols'];
  type: CoffeeLogicTypes['type'];

  /**
   * Конструктор
   * @param {string} type - тип страницы (mobile/desktop)
   */
  constructor(type: DeviceType, symbols: CoffeeLogicTypes['symbols']) {
    this.init(type, symbols);
  }

  /**
   * Инициализация (назначение параметров по умолчанию)
   * @param {string} type - тип страницы (mobile/desktop)
   * @param {object} symbols - объект по ключам-символам с информацией
   */
  init = (type: DeviceType, symbols: CoffeeLogicTypes['symbols']) => {
    this.step = STEPS.MAIN;
    this.usedSymbols = _cloneDeep(initialData.usedSymbols);
    this.selectedSymbols = _cloneDeep(initialData.selectedSymbols);
    this.symbols = symbols;
    this.type = type;
  };

  /**
   * Получить следующий шаг
   */
  nextStep = () => {
    this.step += 1;
  };

  /**
   * Установить значение шага
   * @param {number} step - номер шага
   */
  setStep = (step: CoffeeLogicTypes['step']) => {
    this.step = step;
  };

  /**
   * Получить текущий шаг
   */
  getCurrentStep = () => this.step;

  /**
   * Получить предыдущий шаг в зависимости от текущего
   * @param {string} time - время (индикатор)
   */

  getPrevStep = (time: TimeType) => {
    switch (time) {
      case 'present':
        return STEPS.PAST_DESCRIPTION;
      case 'future':
        return STEPS.PRESENT_DESCRIPTION;
      default:
        return STEPS.MAIN;
    }
  };

  /**
   * Получить следующий шаг в зависимости от текущего
   * @param {string} time - время (индикатор)
   */
  getNextStep = (time: TimeType) => {
    if (time === 'past') {
      if (_isNumber(this.selectedSymbols.present)) {
        return STEPS.PRESENT_DESCRIPTION;
      }

      if (_isNumber(this.selectedSymbols.past)) {
        return STEPS.PRESENT;
      }

      return STEPS.PAST_DESCRIPTION;
    }

    if (time === 'present') {
      if (_isNumber(this.selectedSymbols.future)) {
        return STEPS.FUTURE_DESCRIPTION;
      }

      if (_isNumber(this.selectedSymbols.present)) {
        return STEPS.FUTURE;
      }

      return STEPS.PRESENT_DESCRIPTION;
    }

    return STEPS.RESULT;
  };

  /**
   * Получить предыдущий индикатор времени в зависимости от текущего
   * @param {string} time - время (индикатор)
   */

  getPrevTime = (time: TimeType) => {
    switch (time) {
      case 'present':
        return 'past';
      case 'future':
        return 'present';
      default:
        return '';
    }
  };

  /**
   * Получить следующий индикатор времени в зависимости от текущего
   * @param {string} time - время (индикатор)
   */

  getNextTime = (time: TimeType) => {
    switch (time) {
      case 'past':
        return 'present';
      case 'present':
        return 'future';
      case 'future':
        return 'results';
      default:
        return '';
    }
  };

  /**
   * Получить рандомное значение от 0 до max включительно
   * @param {number} max - максимальное значение
   */

  getRandomValue = <Val extends number = number>(max: number): Val =>
    // eslint-disable-next-line sonarjs/pseudo-random
    Math.floor(Math.random() * (max + 1)) as Val;

  /**
   * Получить рандомную высоту в заданном диапазоне
   * @param {string} time - время (индикатор)
   */
  getRandomHeight = (time: TimeType) =>
    SYMBOLS_HEIGHTS[this.type][time].min +
    this.getRandomValue(SYMBOLS_HEIGHTS[this.type][time].range);

  /**
   * Получить рандомный поворот в заданном диапазоне
   */
  getRandomRotation = () =>
    SYMBOLS_ROTATION.min + this.getRandomValue(SYMBOLS_ROTATION.range);

  /**
   * Получить координаты символа
   * @param {string} time - время (индикатор)
   * @param {number} index - индекс символа
   */
  getRandomCoords = (time: TimeType, index: number) => {
    const coordsLength = SYMBOLS_POSITIONS[this.type][time][index].length;

    return (
      SYMBOLS_POSITIONS[this.type][time][index][
        this.getRandomValue(coordsLength - 1)
      ] || {}
    );
  };

  /**
   * Узнать, был ли символ уже использован
   * @param {string} time - время (индикатор)
   * @param {string} symbolType - тип символа
   * @param {string} value - символ
   */
  isSymbolUsed = (time: TimeType, symbolType: SymbolsTypes, value: 0 | 1 | 2) =>
    (this.usedSymbols[time][symbolType] as number[])?.includes(value) ||
    (this.selectedSymbols[symbolType] as number[])?.includes(value);

  /**
   * Получить рандомную кофейную подложку
   * @param {string} time - время (индикатор)
   * @param {string} type - тип кофейной подложки
   */
  getRandomSubstrate = (time: TimeType, type: SubstrateType) => {
    let randomValue: SymbolType[typeof type];

    do {
      randomValue = this.getRandomValue<SymbolType[typeof type]>(
        SUBSTRATES[type].length - 1,
      );
    } while (this.isSymbolUsed(time, type, SUBSTRATES[type][randomValue]));

    const randomSubstrate = SUBSTRATES[type][randomValue];

    if (type === 'circle') {
      this.usedSymbols[time].circle?.push(randomSubstrate);
      this.selectedSymbols.circle.push(randomSubstrate);
    } else {
      this.usedSymbols[time].side?.push(0);
      this.selectedSymbols.side.push(0);
    }

    return {
      randomSubstrate,
    };
  };

  /**
   * Получить рандомный символ
   * @param {string} time - время (индикатор)
   * @param {string} type - тип символа
   * @param {number} index - порядковый номер символа
   */
  getRandomSymbol = (time: TimeType, type: SymbolsTypes, index: number) => {
    let randomSymbol: SymbolType[typeof type];

    do {
      randomSymbol = this.getRandomValue<SymbolType[typeof type]>(
        this.symbols.length - 1,
      );
      // @ts-expect-error: Argument of type 'number' is not assignable to parameter of type '0 | 1 | 2'
      // TODO(HORO-0): сделать нормально
    } while (this.isSymbolUsed(time, type, randomSymbol));

    // @ts-expect-error: Object is possibly 'undefined'. & Argument of type 'number' is not assignable to parameter of type '0'
    // TODO(HORO-0): сделать нормально
    this.usedSymbols[time][type].push(randomSymbol);

    const height = this.getRandomHeight(time);

    this.usedSymbols[time].heights.push(height);

    const deg = this.getRandomRotation();

    this.usedSymbols[time].rotations.push(deg);

    const coords = this.getRandomCoords(time, index);

    this.usedSymbols[time].positions.push(coords);

    const style = {
      transform: `rotate(${deg}deg)`,
      height: `${height}px`,
      zIndex: this.symbols[randomSymbol].is_bottom_layer ? 2 : 5,
      ...coords,
    };

    return {
      randomSymbol,
      style,
    };
  };

  /**
   * Получить использованную кофейную подложку
   * @param {string} time - время (индикатор)
   * @param {string} type - тип подложки
   * @param {number} index - индекс подложки
   */
  getSubstrateByIndex = (
    time: TimeType,
    type: SubstrateType,
    index: number,
  ) => {
    const usedSubstrate = this.usedSymbols[time][type]?.[index];

    return {
      usedSubstrate,
    };
  };

  /**
   * Получить использованный символ по индексу
   * @param {string} time - время (индикатор)
   * @param {string} type - тип символа
   * @param {number} index - индекс символа
   */
  getSymbolByIndex = (time: TimeType, type: SymbolsTypes, index: number) => {
    const usedSymbol = this.usedSymbols[time][type]?.[index];
    const selectedSymbol = this.selectedSymbols[time];

    const height = this.usedSymbols[time].heights[index];
    const deg = this.usedSymbols[time].rotations[index];
    const coords = this.usedSymbols[time].positions[index];

    const style = {
      transform: `rotate(${deg}deg)`,
      height: `${height}px`,
      ...coords,
    };

    return {
      usedSymbol,
      isActive: (usedSymbol as number) === (selectedSymbol as number),
      style,
    };
  };

  /**
   * Выбрать символ (клик по кофейному пятну)
   * @param {string} time - время (индикатор)
   * @param {string} symbol - символ
   */
  selectSymbol = (time: TimeType, symbol: number) => {
    this.selectedSymbols[time] = symbol;
    this.selectedSymbols.symbols.push(symbol);
  };

  /**
   * Получить выбранный символ (клик по кофейному пятну)
   * @param {string} time - время (индикатор)
   */
  getSelectedSymbol = (time: TimeType) => {
    const selectedSymbol = this.selectedSymbols[time];

    return selectedSymbol;
  };

  /**
   * Получить количество звёзд на текущем шаге
   * @param {number} step - шаг
   * @param {string} size - размер (big/small)
   */
  getStars = (step: CoffeeLogicTypes['step'], size: SizeType) =>
    STARS_COUNT[this.type][step][size];

  /**
   * Получить кофейный символ
   * @param {string} symbol - код символа
   */
  getCoffeeSymbol = (symbol: number) => this.symbols[symbol].coffee;

  /**
   * Получить иконку выбранного знака
   * @param {string} time - время (индикатор)
   */
  getIcon = (time: TimeType) =>
    this.symbols[this.selectedSymbols[time] as number].svg;

  /**
   * Получить заголовок выбранного знака
   * @param {string} time - время (индикатор)
   */
  getTitle = (time: TimeType) =>
    this.symbols[this.selectedSymbols[time] as number].title;

  /**
   * Получить подзаголовок выбранного знака
   * @param {string} time - время (индикатор)
   */
  getSubtitle = (time: TimeType) =>
    this.symbols[this.selectedSymbols[time] as number].subtitle;

  /**
   * Получить полный текст выбранного знака
   * @param {string} time - время (индикатор)
   */
  getText = (time: TimeType) =>
    this.symbols[this.selectedSymbols[time] as number][time];

  /**
   * Получить три символа для вывода результата
   */
  getResult = () => ({
    past: this.symbols[this.selectedSymbols.past as number].codename,
    present: this.symbols[this.selectedSymbols.present as number].codename,
    future: this.symbols[this.selectedSymbols.future as number].codename,
  });
}
