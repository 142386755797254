export const SET_ERROR = 'SET_ERROR';

const initialState: ErrorType = {
  status: 0,
  msg: '',
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const reducer: ReducersTypes<'error'> = (state = initialState, action) => {
  const { type, vars } = action;

  if (type === SET_ERROR) {
    return {
      ...state,
      ...vars,
    };
  }

  return state;
};

// eslint-disable-next-line import/no-default-export
export default reducer;
