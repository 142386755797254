import fetch from 'common/utils/fetch';
import { sentryReactSend } from 'utils/sentry/client';

import type { AppStore } from './configure';

export const GET_VK_VIDEO_DATA = 'GET_VK_VIDEO_DATA';

const initialState: VkVideoWidgetType = {
  data: {
    text: '',
    sign: 'all',
  },
};

export const fetchVkVideoWidgetData =
  (sign?: string) =>
  async (dispatch: AppStore['dispatch'], getState: AppStore['getState']) => {
    try {
      const url = `horoscopes/natal/?sign=${sign || 'all'}`;

      const data = await fetch({
        path: url,
        state: getState(),
        apiVersion: 'v4',
      });

      if (data.error) {
        const err = new Error('Не удалось получить данные виджета VKВидео');
        throw err;
      }

      dispatch({
        type: GET_VK_VIDEO_DATA,
        data: {
          ...data,
          sign: sign || 'all',
        },
      });
    } catch (error) {
      sentryReactSend(error);
      console.error(`${new Date()} ${error}`);
    }
  };

const reducer: ReducersTypes<'vkVideoWidget'> = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state = initialState,
  action,
) => {
  if (action.type === GET_VK_VIDEO_DATA) {
    return {
      ...state,
      data: action.data,
    };
  }

  return state;
};

// eslint-disable-next-line import/no-default-export
export default reducer;
