import fetch from 'common/utils/fetch';
import { sentryReactSend } from 'utils/sentry/client';
import { API } from 'config/constants/api';
import type { AppStore } from './configure';

export const SET_CHINESE_SIGN = 'SET_CHINESE_SIGN';

export const getChineseSign =
  (date: string) =>
  async (dispatch: AppStore['dispatch'], getState: AppStore['getState']) => {
    try {
      const url = `${API.chinese.date}${date}/`;
      const data = await fetch({
        path: url,
        state: getState(),
      });

      if (!data) {
        const err = new Error(
          `${new Date()} Не удалось получить данные китайского знака по дате: ${url}`,
        );
        throw err;
      }

      dispatch({
        type: SET_CHINESE_SIGN,
        data,
      });
    } catch (error) {
      sentryReactSend(error);
      console.error(`${new Date()} ${error}`);
    }
  };

// eslint-disable-next-line @typescript-eslint/default-param-last
const reducer: ReducersTypes<'chinese'> = (state = { sign: '' }, action) => {
  const { type, data } = action;

  if (type === SET_CHINESE_SIGN) {
    return {
      ...state,
      sign: data.sign,
    };
  }

  return state;
};

// eslint-disable-next-line import/no-default-export
export default reducer;
