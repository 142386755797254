import React from 'react';
import cn from 'classnames';

import {
  HTMLReactParserOptions,
  domToReact,
  attributesToProps,
  Element,
} from 'html-react-parser';
import parseJson from 'parse-json';

import { Link } from 'react-router-dom';

import ImageComponent from '@rambler-components/image';

import { Ad } from 'common/components/Ad';

import { safeGet } from 'utils/safeGet';
import { getTop100Markup } from 'common/utils/getTop100Markup';

enum DataAttrName {
  embed = 'data-embed-type',
  block = 'data-block-type',
  advName = 'data-block-adv-name',
  imageData = 'data-block-image-data',
}

export const getParserOptions = ({
  isMobile,
  top100Prefix,
  styles,
  title = '',
  origin = '',
  isBot,
}: {
  isMobile: boolean;
  top100Prefix: string;
  styles: StylesType;
  title: string;
  origin: string;
  isBot: boolean;
}) => {
  const options: HTMLReactParserOptions = {
    replace: (domNode: Element) => {
      if (
        domNode.type === 'tag' &&
        domNode.name === 'a' &&
        safeGet(() => domNode.attribs.href)
      ) {
        const { class: className, href, rel } = domNode.attribs;
        const isHoroUrl = href.includes('horoscopes.rambler.ru');
        const isRamblerUrl = href.includes('rambler.ru');
        const readyUrl = new URL(href, origin);
        const readyRel = isRamblerUrl ? rel : `${rel} nofollow`;

        const getTop100 = getTop100Markup(
          isMobile,
          top100Prefix,
          `link::text_links::${isHoroUrl ? 'inner' : 'outer'}::${isHoroUrl ? href : readyUrl.pathname}`,
        );

        let urlIsOuter = false;

        const outerUrls = ['/article'];

        outerUrls.forEach((outerUrl) => {
          if (readyUrl.pathname.includes(outerUrl)) {
            urlIsOuter = true;
          }
        });

        if (isHoroUrl && !urlIsOuter) {
          return (
            <Link
              to={readyUrl.pathname}
              className={className}
              {...getTop100}
              rel={readyRel}
            >
              {domToReact(domNode.children, options)}
            </Link>
          );
        }

        return (
          <a
            href={href}
            className={className}
            rel={readyRel}
            {...(isRamblerUrl
              ? {}
              : {
                  target: '_blank',
                })}
            {...getTop100}
          >
            {domToReact(domNode.children, options)}
          </a>
        );
      }

      const props = attributesToProps(domNode.attribs);

      if (props?.[DataAttrName.block] === 'code') {
        return null;
      }

      if (props?.[DataAttrName.block] === 'adv') {
        if (props?.[DataAttrName.advName]) {
          return (
            <Ad
              name={props?.[DataAttrName.advName]}
              wrapperClassName={styles.ad}
            />
          );
        }

        return null;
      }

      if (props?.[DataAttrName.embed] === 'twitter') {
        return (
          <div
            dangerouslySetInnerHTML={{
              __html: `
                  <script>window.twttr.widgets.load();</script>
                  ${props?.['data-content']}
                `,
            }}
          />
        );
      }

      if (props?.[DataAttrName.block] === 'image') {
        const imageData: {
          url: string;
          alt: string;
          width: number;
          height: number;
          sourceText: string;
          sourceUrl: string;
        } = parseJson(props?.[DataAttrName.imageData]);

        const isS3 = !imageData.url.includes('rl0.ru');

        return (
          <>
            <ImageComponent
              className={cn(styles.picture, !isBot && styles.pictureLazy)}
              isImg={isBot}
              isS3={isS3}
              src={imageData.url}
              alt={imageData.alt || title}
              isLazy
              width={640}
              height={360}
            />
            {imageData.sourceText && (
              <span className={styles.pictureSource}>
                Фото: {imageData.sourceText}
              </span>
            )}
          </>
        );
      }

      return domNode;
    },
  };

  return options;
};
