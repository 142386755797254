import { Request } from 'express';
import Cookies from 'js-cookie';
import isEmpty from 'lodash.isempty';

import { SET_RUNTIME_VARIABLES } from 'common/redux/runtime';
import { SET_ERROR } from 'common/redux/error';
import { SET_META_DATA } from 'common/redux/meta';
import { fetchLikesData } from 'common/redux/likes';
import { getCalendarData, setCalendarData } from 'common/redux/calendar';

import { safeGet } from 'utils/safeGet';

import fetch from 'common/utils/fetch';
import { parseDraft } from 'common/utils/draftParser';
import { sentryReactSend } from 'utils/sentry/client';
import { API } from 'config/constants/api';
import { getDateRange, getYearAndMonth } from 'common/utils/getCalendar';

import {
  BAD_REQUEST_STATUS,
  INTERNAL_SERVER_ERROR_STATUS,
} from 'config/constants/statusCodes';
import type { AppStore } from './configure';

export const SET_PAGE_DATA = 'SET_PAGE_DATA';
export const ADD_CARDS_DATA = 'ADD_CARDS_DATA';
export const SET_DASHBOARD_DATA = 'SET_DASHBOARD_DATA';
export const SET_CONTENT_LIKES_LINK = 'SET_CONTENT_LIKES_LINK';

const SHAKESPEARE_DRAFT_TEXT =
  'На «Улице Шекспира» верят в судьбу, а вы? Задайте свой вопрос с ответом «да» или «нет» и узнайте, что вас ждет.';

type RuntimeVarsType = Pick<
  RuntimeType,
  // 'currentPage' |
  // 'currentSection' |
  | 'currentParams'
  | 'puid6'
  // 'puid18' |
  | 'puid15'
  | 'apiUrl'
  | 'nextPage'
  | 'hasNextPage'
  | 'top100'
  | 'currentBurgerSection'
  | 'isPremium'
> &
  Partial<Pick<RuntimeType, 'fullUrl' | 'path'>> &
  RuntimeBoundFields;

const getUserData = (req: Request) => {
  let haccount = null;

  if (__SERVER__ && req.cookies && req.cookies.haccount) {
    haccount = req.cookies.haccount;
  } else if (Cookies.get('haccount')) {
    haccount = Cookies.get('haccount');
  }

  const userData: {
    birthday?: string;
    gender?: string;
    partner_birthday?: string;
    partner_gender?: string;
  } = {};

  if (haccount) {
    const horoscopesAccount = JSON.parse(haccount);

    userData.birthday = horoscopesAccount.birthday;
    userData.gender = horoscopesAccount.gender;

    const horoscopesAccountPartner =
      horoscopesAccount.partners && horoscopesAccount.partners[0];

    if (horoscopesAccountPartner) {
      userData.partner_birthday = horoscopesAccountPartner.birthday;
      userData.partner_gender = horoscopesAccountPartner.gender;
    }

    return { userData };
  }

  return undefined;
};

const getCodenames = (data: any) => {
  const codeNames: string[] = [];

  if (data) {
    if (data.content) {
      codeNames.push(data.content.likes.codename);
    }

    if (data.cards) {
      data.cards.forEach((card: ICardProps) => {
        if (card.likes) {
          codeNames.push(card.likes.codename);
        }
      });
    }
  }

  return codeNames.join(',');
};

export const fetchTailData =
  (params: string) =>
  async (
    dispatch: AppStore['dispatch'],
    getState: AppStore['getState'],
    { req }: { req: Request },
  ) => {
    try {
      const { apiUrl, isMobile, currentPage, currentSection } =
        getState().runtime;

      if (!apiUrl) {
        return;
      }

      const url = `${apiUrl}?feed=true${params || '/'}`;
      const data = await fetch({
        path: url,
        state: getState(),
        additionalHeaders: getUserData(req),
      });

      const isError = data.error && data.status >= BAD_REQUEST_STATUS;

      if (!data || isError) {
        const err = new Error(
          `${new Date()} Не удалось получить данные хвоста страницы: ${url}`,
        );
        throw err;
      }

      await dispatch({
        type: ADD_CARDS_DATA,
        name: currentPage,
        section: currentSection,
        data: data.cards,
        isMobile,
      } as PageAction);

      await dispatch({
        type: SET_RUNTIME_VARIABLES,
        vars: {
          nextPage: data.current_page + 1 || 1,
          hasNextPage: !data.is_last_page,
        },
      });

      await dispatch(fetchLikesData(getCodenames(data)));
    } catch (error) {
      sentryReactSend(error);
      console.error(`${new Date()} ${error}`);
    }
  };

export const fetchPageData =
  <Page extends PageName>(
    pageName: Page,
    sectionName: APISections[Page],
    /* eslint-disable @typescript-eslint/default-param-last */
    params: RuntimeType['currentParams'] = {
      url: '',
    },
    isSilent = false,
    /* eslint-enable @typescript-eslint/default-param-last */
    runtimeParams: {
      top100: string;
      isBubblesMoved?: boolean;
      isMenuBubblesHidden?: boolean;
    },
    // eslint-disable-next-line max-params
  ) =>
  async (
    dispatch: AppStore['dispatch'],
    getState: AppStore['getState'],
    { req }: { req: Request },
    // eslint-disable-next-line sonarjs/cognitive-complexity
  ) => {
    try {
      const paramsUrl = params.url ? `${params.url}/` : '';
      const url = `${pageName}/${
        API[pageName][
          // TODO(HORO-0): Подумать как объяснить TSу,
          // что APISections[Page] и keyof APIsUrls[Page] одно и то же
          sectionName as unknown as keyof APIsUrls[Page]
        ]
      }${paramsUrl}`;

      const state = getState();

      const data = await fetch({
        path: url,
        state,
        additionalHeaders: getUserData(req),
      });

      const isError = safeGet(
        () => data.error && data.status >= BAD_REQUEST_STATUS,
        false,
      );
      const isFullScreen = data?.content?.special?.is_fullscreen;
      const newParams = {
        ...params,
        isFullScreen,
      };
      const hasShakespeareBranding =
        pageName === 'fortune' &&
        sectionName === 'taroshort' &&
        state.runtime.adminData.settings.shakespeare_enabled;

      const firstTailPageData: {
        current_page: number;
        is_last_page: boolean;
        cards: ICardProps[];
      } = {
        current_page: 0,
        is_last_page: false,
        cards: [],
      };

      if (data && !isError) {
        data.cards = [
          ...safeGet(() => data.cards, []),
          ...safeGet(() => firstTailPageData.cards, []),
        ];

        if (hasShakespeareBranding && data.content?.draft?.blocks[0]?.text) {
          data.content.draft.blocks[0].text = SHAKESPEARE_DRAFT_TEXT;
        }

        await dispatch({
          type: SET_PAGE_DATA,
          name: pageName,
          section: sectionName,
          isMobile: state.runtime.isMobile,
          data: {
            pageTitle: data.page_title || null,
            content: data.content || null,
            cards: data.cards || null,
            bubbles: data.bubbles || null,
            topics: data.topics || null,
          },
          // TSу тяжело понять, что 2 сложных типа эквивалентны
        } as PageAction);

        if (data.content && data.content.inner_blocks) {
          data.content.inner_blocks.forEach((block: any) => {
            if (block.calendar) {
              dispatch(
                setCalendarData(block.calendar.type, block.calendar.dates_list),
              );
            }
          });
        }

        if (data.content && data.content.calendar) {
          const { type, min, max } = data.content.calendar;
          const { year, month } = getYearAndMonth(min, max, params.hairPeriod);
          const dateRange = getDateRange(year, month);

          dispatch(getCalendarData(type, dateRange.start, dateRange.end));
        }
      } else {
        const err = new Error(
          safeGet(
            () => data.error,
            `${new Date()} Не удалось получить данные страницы: ${url}`,
          ),
        );

        // @ts-expect-error: Property 'status' does not exist on type 'Error'
        err.status = safeGet(() => data.status, INTERNAL_SERVER_ERROR_STATUS);
        throw err;
      }

      if (!isSilent) {
        if (data && !isError) {
          await dispatch({
            type: SET_META_DATA,
            data: {
              ...data.meta,
              date: safeGet(() => data.content.date),
            },
          });
        }

        const { top100, isBubblesMoved, isMenuBubblesHidden } = runtimeParams;

        const paramsUrlForBurger = params.url ? `-${params.url}` : '';

        const currentBurgerSection = `${pageName}-${sectionName}${paramsUrlForBurger}`;

        const vars = {
          currentPage: pageName as RuntimeVarsType['currentPage'],
          currentSection: sectionName as RuntimeVarsType['currentSection'],
          currentParams: newParams || null,
          puid6: pageName,
          puid18: sectionName,
          puid15:
            pageName === 'articles' && sectionName === 'detail'
              ? 'article'
              : null,
          apiUrl: url,
          nextPage: safeGet(() => firstTailPageData.current_page, 0) + 1,
          hasNextPage: safeGet(() => !firstTailPageData.is_last_page, true),
          isBubblesMoved,
          isMenuBubblesHidden,
          top100: top100 || `${pageName}_${sectionName}`,
          currentBurgerSection,
          isPremium:
            pageName === 'articles' &&
            sectionName === 'detail' &&
            data.content.article.is_commercial,
        } as RuntimeVarsType;

        if (__CLIENT__) {
          vars.fullUrl = `${window.location.origin}${window.location.pathname}`;
          vars.path = window.location.pathname;
        }

        await dispatch({
          type: SET_RUNTIME_VARIABLES,
          vars: vars as Partial<RuntimeType> & RuntimeBoundFieldsRaw,
        });
      }

      await dispatch(fetchLikesData(getCodenames(data)));
    } catch (error) {
      sentryReactSend(error);
      console.error(`${new Date()} ${error}`);
      dispatch({
        type: SET_ERROR,
        vars: {
          status: error.status,
          msg: error.message,
        },
      });
    }
  };

export const fetchDashboardDateData =
  (
    params: RuntimeType['currentParams'] = {
      url: '',
    },
  ) =>
  async (
    dispatch: AppStore['dispatch'],
    getState: AppStore['getState'],
    { req }: { req: Request },
  ) => {
    try {
      const url = `dashboard${params.url || ''}`;

      const state = getState();

      const data = await fetch({
        path: url,
        state,
        additionalHeaders: getUserData(req),
      });
      const isError = safeGet(
        () => data.error && data.status >= BAD_REQUEST_STATUS,
        false,
      );

      if (data && !isError) {
        await dispatch({
          type: SET_DASHBOARD_DATA,
          name: state.runtime.currentPage,
          section: state.runtime.currentSection,
          isMobile: state.runtime.isMobile,
          data: {
            pageTitle: data.page_title || null,
            content: data.content || null,
          },
          // TSу тяжело понять, что 2 сложных типа эквивалентны
        } as PageAction);
      } else {
        const err = new Error(
          safeGet(
            () => data.error,
            `${new Date()} Не удалось получить данные дашборда при клике на дату: ${url}`,
          ),
        );

        // @ts-expect-error: Property 'status' does not exist on type 'Error'
        err.status = safeGet(() => data.status, INTERNAL_SERVER_ERROR_STATUS);
        throw err;
      }

      if (data && !isError) {
        await dispatch({
          type: SET_META_DATA,
          data: {
            ...data.meta,
            date: safeGet(() => data.content.date),
          },
        });
      }

      const vars = {
        currentParams: params || null,
      } as Partial<RuntimeVarsType>;

      await dispatch({
        type: SET_RUNTIME_VARIABLES,
        vars: vars as Partial<RuntimeType> & RuntimeBoundFieldsRaw,
      });

      if (data && !isError) {
        await dispatch(fetchLikesData(getCodenames(data)));
      }
    } catch (error) {
      sentryReactSend(error);
      console.error(`${new Date()} ${error}`);
      dispatch({
        type: SET_ERROR,
        vars: {
          status: error.status,
          msg: error.message,
        },
      });
    }
  };

const getContentWithParsedDraft = (content: ICardProps, isMobile: boolean) => {
  if (content) {
    const newContent = { ...content };

    if (newContent.draft) {
      newContent.draftParsed = parseDraft({
        draftStateInRow: newContent.draft,
        id: newContent.id,
        isMobile,
      });
    }

    if (
      newContent.dashboard_tarot_teaser &&
      newContent.dashboard_tarot_teaser.draft
    ) {
      newContent.dashboard_tarot_teaser.draftParsed = parseDraft({
        draftStateInRow: newContent.dashboard_tarot_teaser.draft,
        id: newContent.dashboard_tarot_teaser.id,
        isMobile,
      });
    }

    if (
      newContent.dashboard_biorhythms &&
      newContent.dashboard_biorhythms.data
    ) {
      newContent.dashboard_biorhythms.data.forEach((item) => {
        if (item.draft) {
          item.draftParsed = parseDraft({
            draftStateInRow: item.draft,
            id: item.id,
            isMobile,
          });
        }
      });
    }

    if (content.inner_blocks) {
      newContent.inner_blocks = content.inner_blocks.map((block, index) => {
        const draftProp = block.draft
          ? {
              draftParsed: parseDraft({
                draftStateInRow: block.draft,
                id: index,
                isMobile,
              }),
            }
          : {};

        return {
          ...block,
          ...draftProp,
        };
      });
    }

    return newContent;
  }

  return content;
};

export const setContentLikesLink =
  (link: string) =>
  (dispatch: AppStore['dispatch'], getState: AppStore['getState']) => {
    const state = getState();

    dispatch({
      type: SET_CONTENT_LIKES_LINK,
      name: state.runtime.currentPage,
      section: state.runtime.currentSection,
      link,
    } as PageAction);
  };

// eslint-disable-next-line @typescript-eslint/default-param-last
const reducer: ReducersTypes<'page'> = (state = {}, action) => {
  switch (action.type) {
    case SET_PAGE_DATA: {
      const { content } = action.data;
      let { cards } = action.data;

      const readyContent = getContentWithParsedDraft(content, action.isMobile);

      if (cards) {
        cards = cards.map((card, index) => {
          const draftProp = card.draft
            ? {
                draftParsed: parseDraft({
                  draftStateInRow: card.draft,
                  id: index,
                  isMobile: action.isMobile,
                }),
              }
            : {};

          return {
            ...card,
            ...draftProp,
          };
        });
      }

      return {
        ...state,
        [action.name]: {
          ...(state[action.name] ? state[action.name] : {}),
          [action.section]: {
            ...safeGet(() => state[action.name][action.section], {}),
            ...action.data,
            cards,
            content: readyContent,
          },
        },
      };
    }

    case SET_CONTENT_LIKES_LINK: {
      const sectionData = state[action.name][action.section];
      const contentData = sectionData?.content;
      const linkData =
        !contentData || isEmpty(contentData) ? {} : contentData.link;

      return {
        ...state,
        [action.name]: {
          ...(state[action.name] ? state[action.name] : {}),
          [action.section]: {
            ...sectionData,
            content: {
              ...contentData,
              link: {
                ...linkData,
                link: action.link,
              },
            },
          },
        },
      };
    }

    case ADD_CARDS_DATA: {
      let cards = action.data;

      if (cards) {
        cards = cards.map((card, index) => {
          const draftProp = card.draft
            ? {
                draftParsed: parseDraft({
                  draftStateInRow: card.draft,
                  id: index,
                  isMobile: action.isMobile,
                }),
              }
            : {};

          return {
            ...card,
            ...draftProp,
          };
        });
      }

      return {
        ...state,
        [action.name]: {
          ...state[action.name],
          [action.section]: {
            ...state[action.name][action.section],
            cards: [...state[action.name][action.section].cards, ...cards],
          },
        },
      };
    }

    case SET_DASHBOARD_DATA: {
      const { content } = action.data;

      const readyContent = getContentWithParsedDraft(content, action.isMobile);

      return {
        ...state,
        [action.name]: {
          ...(state[action.name] ? state[action.name] : {}),
          [action.section]: {
            ...safeGet(() => state[action.name][action.section], {}),
            ...action.data,
            content: readyContent,
          },
        },
      };
    }

    default:
      return state;
  }
};

// eslint-disable-next-line import/no-default-export
export default reducer;
