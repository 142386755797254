import _compact from 'lodash.compact';

import { SPLIT_VALUE_KEY } from 'config/constants/splits';

export const splitsToArray = (splits: RuntimeType['splits']) =>
  splits
    ? _compact(
        Object.keys(splits).map((key) => {
          if (key === SPLIT_VALUE_KEY) return '';

          return `${key}_${splits[key]}`;
        }),
      )
    : [];
