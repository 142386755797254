/**
 * Разметка топ-100
 * @param isMobile - мобильная версия
 * @param top100Prefix - префик разметки
 * @param tail - хвост разметки
 * @returns объект полной разметки (префикс + хвот) исходя из версии (мобильная или нет)
 */

export const getTop100Markup = (
  isMobile: boolean,
  top100Prefix: string,
  tail?: string,
) => {
  const top100tail = tail ? `::${tail}` : '';

  return {
    [`data-horo${isMobile ? '-mobile' : ''}-new`]: `${top100Prefix}${top100tail}`,
  };
};
