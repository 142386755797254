import { useMemo } from 'react';

import { getPureText } from 'utils/getPureText';
import {
  getParagraphType,
  paragraphTypesList,
  PARAGRAPH_TYPE,
} from 'common/utils/clusterContent/getParagraphType';

import { getArticleWithTags } from './getTaggedText';
import { cutArticleLength } from './cut/cutArticleLength';
import { cutFirstBanner } from './cut/cutFirstBanner';
import {
  ParagraphArr,
  ParagraphConfigType,
  CUT_TYPE,
  CUT_TOP100,
} from './typings';

export { CUT_TYPE, CUT_TOP100 };

/** Количество символов, после которых в конце параграфов вставляется баннер */
const BANNER_OFFSET = 500;

export const mobileParagraphsConfig = (paragraphsList: string[]) => {
  // Счетчик символов в тексте
  let clusterBodyLength = 0;
  // Счетчик символов для вставки баннера
  let bannerCharCount = 0;
  // Индекс для опредление типа вставляемого баннера
  let bannerIndex = 0;

  // Массив конфигураций для параграфов
  const paragraphsConfigsList: ParagraphConfigType[] = paragraphsList.map(
    (text) => {
      const { type, config, isMediaContent } = getParagraphType(text);
      let paragraphLength = 0;

      // Эмбеды и заголовки не участвуют в подсчете символов
      if (
        type !== PARAGRAPH_TYPE.EMBED &&
        type !== PARAGRAPH_TYPE.NO_INCREMENT_TAG
      ) {
        paragraphLength = getPureText(text).length;
        clusterBodyLength += paragraphLength;
        bannerCharCount += paragraphLength;
      }

      // Флаг рендера баннера через заданное количество символов
      let bannerIsRender = false;

      if (bannerCharCount > BANNER_OFFSET) {
        bannerCharCount = 0;
        bannerIsRender = true;
        bannerIndex += 1;
      }

      const result: ParagraphConfigType = {
        type,
        paragraphLength,
        accumulatedLength: clusterBodyLength,
        config,
        Component: paragraphTypesList[type],
        bannerIndex: bannerIsRender ? bannerIndex : undefined,
        text,
        isMediaContent,
      };

      return result;
    },
  );

  return paragraphsConfigsList;
};

const getCut = (
  paragraphsList: ParagraphConfigType[],
  cut?: CUT_TYPE,
): ParagraphArr => {
  switch (cut) {
    case CUT_TYPE.ARTICLE_LENGTH: {
      return cutArticleLength(paragraphsList);
    }

    case CUT_TYPE.FIRST_BANNER: {
      return cutFirstBanner(paragraphsList);
    }

    case CUT_TYPE.NO_CUT: {
      return {
        paragraphsListBeforeCut: [...paragraphsList],
        paragraphsListInCut: [],
      };
    }

    // eslint-disable-next-line sonarjs/no-duplicated-branches
    default: {
      return {
        paragraphsListBeforeCut: [...paragraphsList],
        paragraphsListInCut: [],
      };
    }
  }
};

/**
 * Получение объект с массивами параграфов для вывода текста до и внутри CUT
 * @param clusterBody - строковое представление тела кластера (cluster.body)
 * @param autoTags - конфигурация вставляемых автотегов для тела кластера
 * @param cut - тип применяемого CUT
 */
export const useParagraphsMob = (
  clusterBody: string,
  autoTags: AutoTags,
  cut: CUT_TYPE,
) => {
  const paragraphsList = useMemo(
    () =>
      getArticleWithTags(clusterBody, autoTags)
        .replace(/\n\n\s\n\n/g, '\n\n')
        .replace(/\n\s{2,}/g, ' ')
        .replace(
          /<(\w+)>\n\n([а-яА-Яa-zA-Z])/g,
          (match, p1, p2) => `<${p1}>${p2}`,
        )
        .replace(/<(\w+)>\n\n+<(\w+)>/g, (match, p1, p2) => `<${p1}><${p2}>`)
        .replace(/<\/(\w+)>\n<\/(\w+)>/g, (match, p1, p2) => `</${p1}></${p2}>`)
        .replace(/(<(\w+)>)+\n\n(<\/(\w+)>)+/g, '')
        .split('\n\n')
        .map((paragraph) => {
          const startsWithTwoDivs = /^(<div>){2,}/g;
          const endsWithTwoDivs = /(<\/div>){2,}$/g;

          if (
            startsWithTwoDivs.test(paragraph) &&
            endsWithTwoDivs.test(paragraph)
          ) {
            return paragraph.replace(/(^(<div>){2,})|((<\/div>){2,}$)/g, '');
          }

          return paragraph;
        }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const paragraphsConfigsList = useMemo(
    () => mobileParagraphsConfig(paragraphsList),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const result = useMemo(() => getCut(paragraphsConfigsList, cut), []); // eslint-disable-line react-hooks/exhaustive-deps

  return result;
};
