import { PARAGRAPH_TYPE } from 'common/utils/clusterContent/getParagraphType';

export interface ParagraphConfigType {
  /** Тип параграфа для рендера */
  type: PARAGRAPH_TYPE;
  /** Текст/верстка самого параграфа */
  text: string;
  /** Количество символов в параграфе */
  paragraphLength: number;
  /** Количество символов во всех предыдущих + этом параграфе */
  accumulatedLength: number;
  /** Конфигурация параграфа */
  config?: TagConfigType | EmbedConfigType;
  /** Компонент отрисовки параграфа */
  Component: any;
  /** Флаг, является ли параграф медийным */
  isMediaContent: boolean;
  /** Номер вставляемого баннера */
  bannerIndex?: number;
}

export interface ParagraphArr {
  /** Параграфы, вставляемые до ката */
  paragraphsListBeforeCut: ParagraphConfigType[];
  /** Параграфы, вставляемые в кат */
  paragraphsListInCut: ParagraphConfigType[];
}

/** Тип применяемого CUT */
export enum CUT_TYPE {
  /** Без ката */
  NO_CUT,
  /** Коротки кат: Cut (укороченный) desktop */
  SHORT,
  /** Начало после первого баннера + параграф: CUT (укороченный) mobile */
  FIRST_BANNER,
  /** Ориентир на количество символов в тексте: CUT (стандартный) mobile */
  ARTICLE_LENGTH,
}

export const CUT_TOP100 = {
  [CUT_TYPE.NO_CUT]: '',
  [CUT_TYPE.SHORT]: 'readmore::cut_short',
  [CUT_TYPE.FIRST_BANNER]: 'readmore::cut_short',
  [CUT_TYPE.ARTICLE_LENGTH]: 'readmore::cut_standart',
};
