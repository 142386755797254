import parser from 'html-react-parser';
import {
  EditorState,
  convertFromRaw,
  RawDraftContentBlock,
  RawDraftEntity,
} from 'draft-js';
import { convertToHTML } from 'draft-convert';

import { getOrderedAds, getTextBreakPoint } from 'config/constants/content';

import { styleToHtmlMiddleware } from './uses/style-to-html-middleware';
import { blockToHtmlMiddleware } from './uses/block-to-html-middleware';
import { entityToHtmlMiddleware } from './uses/entity-to-html-middleware';

import { getParserOptions } from './uses/getParserOptions';

const adWaitList = [
  'header-one',
  'header-two',
  'header-three',
  'header-four',
  'header-five',
  'header-six',
  'ordered-list-item',
  'unordered-list-item',
  'author',
];

interface MediaRawDraftContentBlock extends RawDraftContentBlock {
  banner?: boolean;
}

interface MediaRawDraftEntity extends RawDraftEntity {
  banner?: boolean;
}

interface MediaRawDraftContentState {
  blocks: MediaRawDraftContentBlock[];
  entityMap: Record<string, MediaRawDraftEntity>;
}

export const parseDraft = ({
  draftStateInRow,
  id,
  isMobile,
  adShift,
  withAnchors,
}: {
  draftStateInRow: MediaRawDraftContentState;
  id: number | string;
  isMobile: boolean;
  adShift?: number;
  withAnchors?: boolean;
}) => {
  let contentLength = 0;
  let currentEntryKey = 0;
  let adCount = adShift || 0;
  const extAdName = isMobile ? 'content4' : 'center';
  const adOrdered = getOrderedAds(isMobile);
  const textBreakPoint = getTextBreakPoint(isMobile);
  const modifyedDraftState: MediaRawDraftContentState = {
    blocks: [],
    entityMap: {},
  };

  draftStateInRow.blocks.forEach((block) => {
    const modifyedBlock = { ...block };

    if (!modifyedBlock.banner) {
      if (modifyedBlock.text && modifyedBlock.text.length) {
        const clearText = modifyedBlock.entityRanges.length
          ? // т.к. если там вообще ничего не будет - ничего не сконвертируется
            modifyedBlock.text
          : modifyedBlock.text
              .replace(/\s{2,}/g, ' ')
              .replace(/^\s/g, '')
              .replace(/\s$/g, '');

        modifyedBlock.text = clearText;

        contentLength += clearText.length;
      }

      if (modifyedBlock.type === 'header-two' && withAnchors) {
        modifyedBlock.data = { ...modifyedBlock.data, withAnchors: true };
      }

      modifyedDraftState.blocks.push(modifyedBlock);

      if (
        contentLength >= textBreakPoint &&
        adWaitList.indexOf(modifyedBlock.type) === -1
      ) {
        const adName =
          adCount < adOrdered.length ? adOrdered[adCount] : extAdName;

        modifyedDraftState.blocks.push({
          data: {},
          depth: 0,
          entityRanges: [],
          inlineStyleRanges: [],
          key: `${id}-ad-${adCount}-${adName}`,
          text: adName,
          type: 'adv',
        });

        contentLength = 0;
        adCount += 1;
      }
    }
  });

  Object.keys(draftStateInRow.entityMap).forEach((key) => {
    const modifyedEntry = { ...draftStateInRow.entityMap[key] };

    if (!modifyedEntry.banner) {
      modifyedDraftState.entityMap[currentEntryKey] = modifyedEntry;
      currentEntryKey += 1;
    }
  });

  const contentState = convertFromRaw(modifyedDraftState);
  const editorState = EditorState.createWithContent(contentState);

  const html = convertToHTML({
    styleToHTML: styleToHtmlMiddleware,
    blockToHTML: blockToHtmlMiddleware,
    entityToHTML: entityToHtmlMiddleware,
  })(editorState.getCurrentContent())
    .replace(/\s{2,}/g, '')
    .replace(/<p>\s?<\/p>/g, '')
    .replace(/<div>\s?<\/div>/g, '')
    // убрать если потребуется использовать <figure />
    // сделано т.к. всё что atomic оборачивается в <figure /> чего нам не надо
    .replace(/<figure>/g, '')
    .replace(/<\/figure>/g, '')
    // если в конца строки будет конструкция как ниже - значит реклама встала в самый конец
    // а т.к. этого следует избегать - вырезаем её
    .replace(
      /<div data-block-type="adv" data-block-adv-name="\w+">\w+<\/div>$/g,
      '',
    );

  return html;
};

/**
 * Получение React-элементов из строки с html
 */
export const jsxFromHtmlString = ({
  html,
  isMobile,
  top100Prefix,
  styles,
  title,
  origin,
  isBot,
}: {
  html: string;
  isMobile: boolean;
  top100Prefix: string;
  styles: StylesType;
  title: string;
  origin: string;
  isBot: boolean;
  // eslint-disable-next-line sonarjs/function-return-type
}) => {
  const options = getParserOptions({
    isMobile,
    top100Prefix,
    styles,
    title,
    origin,
    isBot,
  });

  const jsx = parser(html, options);

  return jsx;
};
