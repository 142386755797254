import React, { useEffect } from 'react';

// eslint-disable-next-line import/no-named-as-default
import Helmet from 'react-helmet';

import { createNode } from 'utils/createNode';

import { EMBED_TYPE } from 'common/utils/clusterContent/config';

import { TextParagraph } from '../TextParagraph';

interface PropsType {
  text: string;
  className: string;
  config: EmbedConfigType;
  embeds: IAppState['runtime']['loadedEmbeds'];
  dispatchLoadEmbed: any;
  clusterIndex: number;
  puids: PuidsType;
  isMediaContent?: boolean;
}

/**
 * Параграф с текстом
 *
 * @param text - текст параграфа
 * @param config - объект конфигурации эмбеда
 * @param embeds - объект состояния о загруженных ранее скриптах эмбедов
 * @param dispatchLoadEmbed - помечает тип эмбеда как загруженный
 * @param clusterIndex - индекс кластера в списке кластеров
 * @param disableAdv - флаг отключения рекламы
 * @param puids - рекламные пуиды
 */
export const EmbedParagraph: React.FC<PropsType> = React.memo(
  ({
    text,
    className,
    config,
    embeds,
    dispatchLoadEmbed,
    clusterIndex,
    puids,
    isMediaContent,
  }) => {
    const { type, link, onReload } = config;
    const isQuiz = EMBED_TYPE.QUIZ === type;

    // Данная логика применяется только к embeds с onReload
    useEffect(() => {
      if (onReload) {
        if (clusterIndex === 0) {
          dispatchLoadEmbed(type);
        } else if (!embeds[type]) {
          dispatchLoadEmbed(type);
          createNode({
            src: link,
            callback: onReload,
          });
        } else {
          onReload();
        }
      }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <>
        {isQuiz && (
          <Helmet>
            <script src="https://quiz.rambler.ru/widget/sdk.js" async />
          </Helmet>
        )}
        <TextParagraph
          className={className}
          text={text}
          puids={puids}
          isMediaContent={isMediaContent}
        />
      </>
    );
  },
  () => true,
);

EmbedParagraph.displayName = 'EmbedParagraph';
