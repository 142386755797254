/* eslint-disable @typescript-eslint/no-magic-numbers */
import dateAdd from 'date-fns/add';
import eachWeekOfInterval from 'date-fns/eachWeekOfInterval';
import lastDayOfMonth from 'date-fns/lastDayOfMonth';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';

import { MONTHS_ENG } from 'config/constants/calendar';

/**
 * Склонение существительного
 * @param number - число
 * @param titles - список строк со склоняемым словом
 * @returns правильное склонение
 */

export const getDaysNoun = (number: number, titles: string[]) => {
  const cases = [2, 0, 1, 1, 1, 2];
  const casesIndex = number % 10 < 5 ? number % 10 : 5;

  return titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[casesIndex]];
};

/**
 * Получение года и месяца по min и max датам из api v3 и месяца
 * @param min - мин дата
 * @param max - макс дата
 * @param month - название месяца
 * @returns год и месяц
 */
export const getYearAndMonth = (min: string, max: string, month?: string) => {
  if (month) {
    const minYear = new Date(min).getFullYear();
    const maxYear = new Date(max).getFullYear();
    const monthIndex = MONTHS_ENG.indexOf(month);

    const year =
      isAfter(new Date(maxYear, monthIndex, 2), new Date(maxYear, 0, 1)) &&
      isBefore(new Date(maxYear, monthIndex, 1), new Date(max))
        ? maxYear
        : minYear;

    return {
      year,
      month: monthIndex,
    };
  }

  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  return {
    year: currentYear,
    month: currentMonth,
  };
};

/**
 * Диапазон дат для отображения текущего месяца
 * @param year - год
 * @param month - месяц (с 0)
 * @returns начальная и конечная дата для отображения текущего месяца
 */
export const getDateRange = (year: number, month: number) => {
  const firstDate = new Date(year, month, 1);
  const result = eachWeekOfInterval(
    {
      start: firstDate,
      end: lastDayOfMonth(firstDate),
    },
    {
      weekStartsOn: 1,
    },
  );

  const start = new Date(result[0]);
  const end = dateAdd(new Date(result[result.length - 1]), { weeks: 1 });

  return {
    start,
    end,
  };
};

/**
 * Получение даты из урла
 * @param min - мин дата
 * @param max - макс дата
 * @param url - строка
 * @returns дату или undefined
 */
export const getDateFromUrl = (min: string, max: string, url: string) => {
  if (!url) return undefined;

  if (/\d{4}-\d{2}-\d{2}/.test(url)) {
    return new Date(url);
  }

  // eslint-disable-next-line sonarjs/slow-regex
  if (/\w+\/\d+/.test(url)) {
    const splitStrs = url.split('/');
    const { year, month } = getYearAndMonth(min, max, splitStrs[0]);

    return new Date(year, month, Number(splitStrs[1]));
  }

  return undefined;
};

/**
 * Получение даты для инициализации календаря
 * @param min - мин дата
 * @param max - макс дата
 * @param today - сегодняшняя дата
 * @param current - выбранная дата
 * @param month - название месяца
 * @returns дата для инициализации календаря
 */
export const getInitDate = ({
  min,
  max,
  today,
  current,
  month,
}: {
  min: string;
  max: string;
  today: Date;
  current: Date | undefined;
  month: string;
}) => {
  if (current) return current;

  if (MONTHS_ENG.includes(month)) {
    const { year, month: initMonth } = getYearAndMonth(min, max, month);

    return new Date(year, initMonth, 1);
  }

  return today;
};
