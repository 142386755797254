type Ref =
  | React.MutableRefObject<HTMLElement | undefined>
  | React.RefObject<HTMLElement | undefined>;

export const isInVerticalViewport = (el: Ref) => {
  if (typeof window !== 'undefined' && el && el.current) {
    const rect = el.current.getBoundingClientRect();
    const screenHeight =
      window.innerHeight || document.documentElement.clientHeight;

    return {
      top: rect.top <= screenHeight && rect.top + rect.height >= 0,
      bottom:
        rect.top + rect.height <= screenHeight && rect.top + rect.height >= 0,
      outViewUpper: rect.top + rect.height < 0,
      needScroll: rect.top || 0,
    };
  }

  throw new Error('isInViewport необходимо использовать только на клиенте');
};

export const isInHorizontalViewport = (
  parent: HTMLElement,
  el: HTMLElement,
  parentScrollPx = 0,
) => {
  if (typeof window !== 'undefined' && el && parent) {
    const rect = el.getBoundingClientRect();

    const parentScroll = Math.abs(parentScrollPx || parent.scrollLeft);
    const parentOffsetWidth = parent.offsetWidth;
    const elLeftPoint = el.offsetLeft;
    const elWidth = Math.ceil(rect.width);
    const elRightPoint = elLeftPoint + elWidth;

    return {
      notInViewLeft: parentScroll > elLeftPoint,
      notInViewRight: parentScroll + parentOffsetWidth < elRightPoint,
      needScrollToViewLeft: elLeftPoint,
      needScrollToViewRight: elLeftPoint - parentOffsetWidth + elWidth,
    };
  }

  throw new Error('isInViewport необходимо использовать только на клиенте');
};
