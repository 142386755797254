const ONE_ITEM = 1;
const FIVE_ITEM = 5;
const TEN_ITEM = 10;
const TWENTY_ITEM = 20;

export const wordDeclination = (
  originalValue: number,
  type: string,
): string => {
  const value = originalValue % 100;
  const num = value % TEN_ITEM;

  if (value > TEN_ITEM && value < TWENTY_ITEM) {
    return type === 'days' ? 'дней' : 'часов';
  }

  if (num > ONE_ITEM && num < FIVE_ITEM) {
    return type === 'days' ? 'дня' : 'часа';
  }

  if (num === ONE_ITEM) {
    return type === 'days' ? 'день' : 'час';
  }

  return type === 'days' ? 'дней' : 'часов';
};
