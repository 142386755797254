export const SET_META_DATA = 'SET_META_DATA';

const initialState: MetaType = {
  title: '',
  description: '',
  keywords: '',
  og_image: '',
  twitter_image: '',
  vk_image: '',
  seo_text: '',
};

// eslint-disable-next-line @typescript-eslint/default-param-last
const reducer: ReducersTypes<'meta'> = (state = initialState, action) => {
  if (action.type === SET_META_DATA) {
    return {
      ...state,
      ...action.data,
    };
  }

  return state;
};

// eslint-disable-next-line import/no-default-export
export default reducer;
