import { connect } from 'react-redux';

import { SET_RUNTIME_EMBEDS } from 'common/redux/runtime';

import type { AppStore } from 'common/redux/configure';
import { EmbedParagraph as EmbedParagraphComponent } from './index';

const mapDispatchToProps = (dispatch: AppStore['dispatch']) => ({
  /**
   * Установка эмбеда как загруженного
   * @param embedType - тип эмбеда, скрипт которого вставляется в header
   */
  dispatchLoadEmbed: (embedType: number) =>
    dispatch({
      type: SET_RUNTIME_EMBEDS,
      name: embedType,
    }),
});

const mapStateToProps = ({ runtime: { loadedEmbeds } }: IAppState) => ({
  loadedEmbeds,
});

export const EmbedParagraph = connect(
  mapStateToProps,
  mapDispatchToProps,
)(EmbedParagraphComponent);
