import React from 'react';
import { TAG_TYPE } from 'common/utils/clusterContent/config';

import { TextParagraph } from '../TextParagraph';

interface PropsType {
  text: string;
  className: string;
  config: TagConfigType;
}

/**
 * Параграф с тегом
 *
 * @param text - текст параграфа
 * @param config - объект конфигурации тэга
 */
export function TagParagraph({ text, className, config }: PropsType) {
  const { type, replace } = config;
  const html = replace(text);

  switch (type) {
    case TAG_TYPE.BLOCKQOUTE:
      return (
        <blockquote
          className={className}
          suppressHydrationWarning
          dangerouslySetInnerHTML={{ __html: html }}
        />
      );
    case TAG_TYPE.H1:
      return (
        <h1
          className={className}
          suppressHydrationWarning
          dangerouslySetInnerHTML={{ __html: html }}
        />
      );
    case TAG_TYPE.H2:
      return (
        <h2
          className={className}
          suppressHydrationWarning
          dangerouslySetInnerHTML={{ __html: html }}
        />
      );
    case TAG_TYPE.H3:
      return (
        <h3
          className={className}
          suppressHydrationWarning
          dangerouslySetInnerHTML={{ __html: html }}
        />
      );
    case TAG_TYPE.H4:
      return (
        <h4
          className={className}
          suppressHydrationWarning
          dangerouslySetInnerHTML={{ __html: html }}
        />
      );
    default:
      return <TextParagraph className={className} text={html} />;
  }
}
