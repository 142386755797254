const MAX_DESCRIPTION_LENGTH = 133;

/**
 * Отдавать обрезанный до 140 символом текст
 * @returns новую строку
 */
export const getShortDescription = (description: string) => {
  const slicedStr = description.slice(0, MAX_DESCRIPTION_LENGTH);
  const resStr = slicedStr.slice(0, slicedStr.lastIndexOf(' '));

  return `${resStr.replace(/[,.\-:;]$/g, '').trim()} ...`;
};
