import isEmpty from 'lodash.isempty';

import fetch from 'common/utils/fetch';
import { sentryReactSend } from 'utils/sentry/client';
import { API } from 'config/constants/api';
import { BODYGRAPH_API_URLS, SEO_URLS } from 'config/constants/humanDesign';

import type { AppStore } from './configure';

export const GET_HUMAN_DESIGN_DATA = 'GET_HUMAN_DESIGN_DATA';
export const GET_HUMAN_DESIGN_DETAILED_DATA = 'GET_HUMAN_DESIGN_DETAILED_DATA';
export const GET_HUMAN_DESIGN_GEO_DATA = 'GET_HUMAN_DESIGN_GEO_DATA';
export const GET_HUMAN_DESIGN_RESULT_DATA = 'GET_HUMAN_DESIGN_RESULT_DATA';
export const SET_IS_GEO_ERROR = 'SET_IS_GEO_ERROR';

const initialState: HumanDesignType = {
  types: [],
  profiles: [],
  lines: [],
  places: {
    countries: [
      {
        country_name: 'Россия',
        country_iso: 'RU',
      },
    ],
  },
  detailed: {
    types: {},
    profiles: {},
  },
  result: {
    active_gates: [],
    defined_centers: [],
    gates_list: {},
  },
  isGeoError: false,
};

export const fetchHumanDesignData =
  (fetchName: keyof APIsUrls['humandesignBlocks']) =>
  async (dispatch: AppStore['dispatch'], getState: AppStore['getState']) => {
    try {
      const hdData = getState().humanDesign[fetchName];
      if (!isEmpty(hdData)) return;

      const url = `humandesign/${API.humandesignBlocks[fetchName]}`;
      const data = await fetch({
        path: url,
        state: getState(),
        apiVersion: 'v4',
      });

      if (!data) {
        const err = new Error(
          `${new Date()} Не удалось получить данные блока дизайна человека: ${url}`,
        );
        throw err;
      }

      dispatch({
        type: GET_HUMAN_DESIGN_DATA,
        name: fetchName,
        data,
      });
    } catch (error) {
      sentryReactSend(error);
      console.error(`${new Date()} ${error}`);
    }
  };

export const fetchHumanDesignDetailedData =
  (fetchName: keyof APIsUrls['humandesignBlocks'], param: string) =>
  async (dispatch: AppStore['dispatch'], getState: AppStore['getState']) => {
    try {
      const hdData = getState().humanDesign.detailed[fetchName][param];
      if (!isEmpty(hdData)) return;

      const url = `humandesign/${API.humandesignBlocks[fetchName]}${param}`;
      const data = await fetch({
        path: url,
        state: getState(),
        apiVersion: 'v4',
      });

      if (!data) {
        const err = new Error(
          `${new Date()} Не удалось получить детальные данные блока дизайна человека: ${url}`,
        );
        throw err;
      }

      dispatch({
        type: GET_HUMAN_DESIGN_DETAILED_DATA,
        name: fetchName,
        param,
        data,
      });
    } catch (error) {
      sentryReactSend(error);
      console.error(`${new Date()} ${error}`);
    }
  };

export const fetchHumanDesignGeoData =
  (
    fetchName: keyof APIsUrls['humandesign'],
    param: 'countries' | 'details',
    query?: string,
  ) =>
  async (dispatch: AppStore['dispatch'], getState: AppStore['getState']) => {
    try {
      const urlParam = param ? `${param}` : '';
      const urlLangQuery = query ? `&${query}` : '';
      const url = `${API.humandesign[fetchName]}${urlParam}?language=ru${urlLangQuery}`;
      const data = await fetch({
        path: url,
        state: getState(),
        apiVersion: 'geo',
      });

      if (!data || (param === 'countries' && !data.countries)) {
        dispatch({
          type: SET_IS_GEO_ERROR,
          name: 'isGeoError',
          data: true,
        });

        const err = new Error(
          `${new Date()} Не удалось получить гео-данные дизайна человека: ${url}`,
        );
        throw err;
      }

      const keys: Record<string, string> = {
        countries: 'countries',
        details: 'result',
      };

      const finalData = data[keys[param]];

      dispatch({
        type: GET_HUMAN_DESIGN_GEO_DATA,
        name: param,
        data: finalData,
      });

      dispatch({
        type: SET_IS_GEO_ERROR,
        name: 'isGeoError',
        data: false,
      });
    } catch (error) {
      sentryReactSend(error);
      console.error(`${new Date()} ${error}`);
    }
  };

export const fetchHumanDesignResultData =
  (query: string) =>
  async (dispatch: AppStore['dispatch'], getState: AppStore['getState']) => {
    try {
      const url = `${BODYGRAPH_API_URLS.bodygraph}?language=ru&${query}`;
      const data = await fetch({
        path: url,
        state: getState(),
        apiVersion: 'hd',
      });

      if (!data) {
        const err = new Error(
          `${new Date()} Не удалось получить результат дизайна человека из API партнера: ${url}`,
        );
        throw err;
      }

      dispatch({
        type: GET_HUMAN_DESIGN_RESULT_DATA,
        name: 'result',
        data,
      });

      const type = SEO_URLS[data.interpretation.type.toLowerCase()];

      dispatch(fetchHumanDesignDetailedData('types', type));
    } catch (error) {
      sentryReactSend(error);
      console.error(`${new Date()} ${error}`);
    }
  };

const reducer: ReducersTypes<'humanDesign'> = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state = initialState,
  action,
) => {
  // Деструктурировать на этом уровне нельзя, так как рушится типизация
  switch (action.type) {
    case GET_HUMAN_DESIGN_DATA:
      return {
        ...state,
        [action.name]: action.data,
      };
    case GET_HUMAN_DESIGN_DETAILED_DATA:
      return {
        ...state,
        detailed: {
          ...state.detailed,
          [action.name]: {
            ...state.detailed[action.name],
            [action.param]: action.data,
          },
        },
      };
    case GET_HUMAN_DESIGN_GEO_DATA:
      return {
        ...state,
        places: {
          ...state.places,
          [action.name]: action.data,
        },
      };

    // eslint-disable-next-line sonarjs/no-duplicated-branches
    case GET_HUMAN_DESIGN_RESULT_DATA: {
      return {
        ...state,
        [action.name]: action.data,
      };
    }

    // eslint-disable-next-line sonarjs/no-duplicated-branches
    case SET_IS_GEO_ERROR: {
      return {
        ...state,
        [action.name]: action.data,
      };
    }

    default:
      return state;
  }
};

// eslint-disable-next-line import/no-default-export
export default reducer;
