import { ParagraphConfigType } from 'common/utils/clusterContent/typings';

/**
 * Минимальное количество символов, при котором необходим CUT
 * https://confluence.rambler-co.ru/pages/viewpage.action?pageId=40541657
 */
const CUT_LIMIT_LENGTH = 2200;
/**
 * Количество символов, после которых начинается CUT
 * https://confluence.rambler-co.ru/pages/viewpage.action?pageId=40541657
 */
const CUT_LIMIT_ACTIVATE = 1800;
/**
 * Минимальное количество символов в CUT
 * https://confluence.rambler-co.ru/pages/viewpage.action?pageId=40541657
 */
const CUT_MIN_LIMIT = 400;

/**
 * Получение массивов параграфов для вставки до и внутрь CUT
 * Механика - CUT (стандартный) mobile
 * https://confluence.rambler-co.ru/pages/viewpage.action?pageId=48184350
 * @param paragraphsList - массив параграфов-строк
 */
export const cutArticleLength = (
  paragraphsList: ParagraphConfigType[],
): {
  paragraphsListBeforeCut: ParagraphConfigType[];
  paragraphsListInCut: ParagraphConfigType[];
} => {
  const bodyLength =
    paragraphsList[paragraphsList.length - 1].accumulatedLength;
  let paragraphsListBeforeCut: ParagraphConfigType[] = [];
  const paragraphsListInCut: ParagraphConfigType[] = [];

  if (bodyLength > CUT_LIMIT_LENGTH) {
    let addInCut = false;

    paragraphsList.forEach((config) => {
      if (config.accumulatedLength > CUT_LIMIT_ACTIVATE && addInCut) {
        paragraphsListInCut.push(config);
      } else {
        paragraphsListBeforeCut.push(config);

        if (
          config.accumulatedLength > CUT_LIMIT_ACTIVATE &&
          bodyLength - config.accumulatedLength > CUT_MIN_LIMIT
        ) {
          addInCut = true;
        }
      }
    });
  } else {
    paragraphsListBeforeCut = [...paragraphsList];
  }

  return { paragraphsListBeforeCut, paragraphsListInCut };
};
