import format from 'date-fns/format';

import {
  TIME_DISPLAY_FORMAT,
  CALENDAR_INIT_DATE,
} from 'config/constants/calendar';

const PENULTIMATE_HOUR = 23;
const PENULTIMATE_MINUTE = 59;

/**
 * Функция проверки value в инпуте времени
 * @param val - строка в инпуте времени
 * @returns является ли время валидным
 */
export const checkTimeValue = (val?: string | null | undefined) => {
  const newVal = val || '';

  if (newVal !== '' && !/\d{1,2}:\d{2}/g.test(newVal)) {
    return false;
  }

  const [hours, minutes] = newVal.split(':');

  return !(
    Number(hours) > PENULTIMATE_HOUR || Number(minutes) > PENULTIMATE_MINUTE
  );
};

/**
 * Строка для вывода времени
 * @param val  - строка в инпуте времен
 * @returns отформатированная строка
 */
export const getDisplayName = (val: string) => {
  if (val && /^\d{2}:\d{2}:\d{2}$/g.test(val)) {
    return format(
      new Date(`${CALENDAR_INIT_DATE}T${val}`),
      TIME_DISPLAY_FORMAT,
    );
  }

  if (val && /^\d:\d{2}$/g.test(val)) {
    return format(
      new Date(`${CALENDAR_INIT_DATE}T0${val}`),
      TIME_DISPLAY_FORMAT,
    );
  }

  if (!val) {
    return '';
  }

  return val;
};
