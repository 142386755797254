import React from 'react';
import { DraftInlineStyleType } from 'draft-js';

enum StyleType {
  strikethrough = 'STRIKETHROUGH',
}

/**
 * Мидлвара преобразования стилей драфта в html
 *
 * все стили, для которых не указано кастомное преобразование,
 * обрабатываются по дефолту пакета draft-convert
 *
 * @param style - draft style
 */
export const styleToHtmlMiddleware = (style: DraftInlineStyleType) => {
  if (style === StyleType.strikethrough) {
    return <s />;
  }

  return null;
};
