/* eslint-disable @typescript-eslint/no-require-imports */
/* eslint-disable sonarjs/deprecation */

import { createStore, applyMiddleware, compose } from 'redux';
import { Request } from 'express';
import thunk, { ThunkMiddleware } from 'redux-thunk';

// https://webpack.js.org/migrate/5/#using-named-exports-from-json-modules
import packageInfo from '../../../package.json';

import reducers from './reducers';

// eslint-disable-next-line import/no-default-export
export default function configureStore(initialState: IAppState, req?: Request) {
  if (__DEV__ && module.hot) {
    module.hot.accept('./reducers');
  }

  const thunkMiddleware = thunk.withExtraArgument({ req }) as ThunkMiddleware<
    // eslint-disable-next-line @typescript-eslint/no-empty-object-type
    {},
    Actions[keyof Actions],
    { req: typeof req }
  >;

  const initState = { ...initialState };

  if (__DEV__) {
    const createLogger = require('./logger').default;
    const loggerMiddleware = createLogger();

    // https://github.com/zalmoxisus/redux-devtools-extension#redux-devtools-extension

    const {
      composeWithDevTools,
    } = require('redux-devtools-extension/developmentOnly');

    const composeEnhancers = composeWithDevTools({
      name: `${packageInfo.name}@${packageInfo.version}`,
    }) as typeof compose; // Типизация из модуля

    // Делать let enhancer: StoreEnhancer;
    // и общий return нельзя
    // Теряется типизация для redux-thunk
    return createStore(
      reducers,
      initState,
      composeEnhancers(applyMiddleware(thunkMiddleware, loggerMiddleware)),
    );
  }

  // Делать let enhancer: StoreEnhancer;
  // и общий return нельзя
  // Теряется типизация для redux-thunk
  return createStore(reducers, initState, applyMiddleware(thunkMiddleware));
}

export type AppStore = ReturnType<typeof configureStore>;
