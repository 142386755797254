import { config } from 'config';

import { EMBED_TYPE } from 'common/utils/clusterContent/config';
import { SPLIT_DEFAULT } from 'config/constants/splits';
import { API } from 'config/constants/api';
import fetch from 'common/utils/fetch';
import { sentryReactSend } from 'utils/sentry/client';
import type { AppStore } from './configure';

export const SET_RUNTIME_VARIABLE = 'SET_RUNTIME_VARIABLE';
export const SET_RUNTIME_VARIABLES = 'SET_RUNTIME_VARIABLES';
export const SET_RUNTIME_EMBEDS = 'SET_RUNTIME_EMBEDS';

const initialState: RuntimeType = {
  config: {
    runProfile: config.RUN_PROFILE,
    apiUrl: config.API_URL,
    rejectUnauthorized: config.HD_REJECT_UNAUTHORIZED,
    httpsProxy: config.HTTPS_PROXY,
    apiTimeoutClient: config.API_TIMEOUT_CLIENT,
    sentryDSN: config.SENTRY_DSN,
    sentrySampleRate: config.SENTRY_SAMPLE_RATE_BROWSER,
    metricsSampleRate: config.METRICS_SAMPLE_RATE,
    apiSubscription: config.API_SUBSCRIPTION,
    newsLetterCrmKeys: config.NEWS_LETTER_CRM_KEYS,
    locationApiUrl: config.API_GEO_LOCATION,
  },
  reloadKey: 1,
  isMobile: false,
  isDebug: false,
  isBot: false,
  banners: config.BANNERS.mobile,
  ruid: '',
  userId: '',
  captchaKey: '',
  origin: '',
  fullUrl: '',
  path: '',
  puid6: '',
  currentPage: 'horoscope',
  currentSection: 'main',
  currentParams: {
    url: '',
  },
  currentBurgerSection: 'horoscopes', // только для topline-mobile
  top100: '',
  apiUrl: '',
  nextPage: 1,
  isBubblesMoved: false,
  isMenuBubblesHidden: false,
  hasNextPage: true,
  isUpdateFeedVisible: false,
  feedbackFormSubmitted: false,
  onboardingShown: true,
  ramblerId: null,
  horoSplit: SPLIT_DEFAULT,
  splits: {},
  requestId: '',
  rsid: config.rsid || '',
  loadedEmbeds: {
    [EMBED_TYPE.INSTAGRAM]: false,
    [EMBED_TYPE.TWITTER]: false,
  },
  adminData: {
    settings: {
      anti_adblock: false,
      themes_disabled: false,
      retrograde_counter: false,
      feedback_visibility: false,
      superfooter_placeholder_400: false,
      promovidzhet_isrussian: false,
      promovidzhet_placeholder: false,
      vk_video_promo: false,
      shakespeare_enabled: false,
    },
    timers: {},
  },
  isPremium: false,
  isBranding: false,
  isStickyTopline: false,
  celebrityList: [],
  PWADisplayMode: 'browser',
  isRussia: false,
};

export const sendFeedbackForm =
  (feedback?: {
    url: string;
    email: string;

    problem_type: string;
    text: string;

    hcaptcha_token: string;
  }) =>
  async (dispatch: AppStore['dispatch'], getState: AppStore['getState']) => {
    try {
      if (feedback) {
        const data = await fetch({
          path: API.feedbacks.main,
          method: 'POST',
          state: getState(),
          body: feedback,
        });

        if (!data || data.error) {
          const err = new Error(
            `${new Date()} Не удалось отправить фидбек: ${API.feedbacks.main}`,
          );
          throw err;
        }
      }

      dispatch({
        type: SET_RUNTIME_VARIABLE,
        name: 'feedbackFormSubmitted',
        value: true,
      });
    } catch (error) {
      sentryReactSend(error);

      console.error(`${new Date()} ${error}`);
    }
  };

// eslint-disable-next-line @typescript-eslint/default-param-last
const reducer: ReducersTypes<'runtime'> = (state = initialState, action) => {
  switch (action.type) {
    case SET_RUNTIME_VARIABLE:
      return {
        ...state,
        [action.name]: action.value,
      };
    case SET_RUNTIME_VARIABLES:
      return {
        ...state,
        ...action.vars,
        // TSу тяжело, не ругайте его
      } as typeof state;
    case SET_RUNTIME_EMBEDS:
      return {
        ...state,
        loadedEmbeds: {
          ...state.loadedEmbeds,
          [action.name]: true,
        },
      };
    default:
      return state;
  }
};

// eslint-disable-next-line import/no-default-export
export default reducer;
