import fetch from 'common/utils/fetch';
import { sentryReactSend } from 'utils/sentry/client';
import { API } from 'config/constants/api';
import type { AppStore } from './configure';

export const SET_LIKES_DATA = 'SET_LIKES_DATA';
export const SET_LIKE_DATA = 'SET_LIKE_DATA';

export const fetchLikesData =
  (codeNames: string) =>
  async (dispatch: AppStore['dispatch'], getState: AppStore['getState']) => {
    try {
      const url = `${API.card.likes}?codenames=${codeNames}`;
      const data = await fetch({
        path: url,
        state: getState(),
      });

      if (!data) {
        const err = new Error(
          `${new Date()} Не удалось получить данные лайков: ${url}`,
        );
        throw err;
      }

      await dispatch({
        type: SET_LIKES_DATA,
        data,
      });
    } catch (error) {
      sentryReactSend(error);
      console.error(`${new Date()} ${error}`);
    }
  };

export const postCardLike =
  (codename: string, hash: string) =>
  async (dispatch: AppStore['dispatch'], getState: AppStore['getState']) => {
    try {
      const url = `${API.card.likes}add/`;
      const data = await fetch({
        path: url,
        method: 'POST',
        state: getState(),
        body: { codename, hash },
      });

      if (!data) {
        const err = new Error(
          `${new Date()} Не удалось отправить данные лайков: ${url}`,
        );
        throw err;
      }

      await dispatch({
        type: SET_LIKE_DATA,
        data,
      });
    } catch (error) {
      sentryReactSend(error);
      console.error(`${new Date()} ${error}`);
    }
  };

export const deleteCardLike =
  (codename: string) =>
  async (dispatch: AppStore['dispatch'], getState: AppStore['getState']) => {
    try {
      const url = `${API.card.likes}remove/`;
      const data = await fetch({
        path: url,
        method: 'POST',
        state: getState(),
        body: { codename },
      });

      if (!data) {
        const err = new Error(
          `${new Date()} Не удалось удалить данные лайков: ${url}`,
        );
        throw err;
      }

      await dispatch({
        type: SET_LIKE_DATA,
        data,
      });
    } catch (error) {
      sentryReactSend(error);
      console.error(`${new Date()} ${error}`);
    }
  };

// eslint-disable-next-line @typescript-eslint/default-param-last
const reducer: ReducersTypes<'likes'> = (state = {}, action) => {
  switch (action.type) {
    case SET_LIKES_DATA: {
      const structuredData =
        action.data && Array.isArray(action.data)
          ? action.data.reduce((result: LikesType, item: LikeType) => {
              result[item.codename] = item;

              return result;
            }, {})
          : {};

      return {
        ...state,
        ...structuredData,
      };
    }

    case SET_LIKE_DATA:
      return {
        ...state,
        [action.data.codename]: action.data,
      };
    default:
      return state;
  }
};

// eslint-disable-next-line import/no-default-export
export default reducer;
