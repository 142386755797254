import fetch from 'common/utils/fetch';
import { sentryReactSend } from 'utils/sentry/client';
import { API } from 'config/constants/api';

import type { AppStore } from './configure';

export const GET_HAIR_STATUS_DATA = 'GET_HAIR_STATUS_DATA';
export const GET_MOON_DATA = 'GET_MOON_DATA';

const initialState: HeaderType = {
  status: '',
};

export const fetchHairData =
  (fetchName: keyof APIsUrls['header']) =>
  async (dispatch: AppStore['dispatch'], getState: AppStore['getState']) => {
    try {
      const data = await fetch({
        path: API.header[fetchName],
        state: getState(),
        apiVersion: 'v4',
      });

      if (!data) {
        const err = new Error(
          `${new Date()} Не удалось получить данные статуса стрижки для хедера`,
        );
        throw err;
      }

      dispatch({
        type: GET_HAIR_STATUS_DATA,
        data,
      });
    } catch (error) {
      sentryReactSend(error);
      console.error(`${new Date()} ${error}`);
    }
  };

export const fetchMoonData =
  (fetchName: keyof APIsUrls['header']) =>
  async (dispatch: AppStore['dispatch'], getState: AppStore['getState']) => {
    try {
      const data = await fetch({
        path: API.header[fetchName],
        state: getState(),
        apiVersion: 'v4',
      });

      if (!data) {
        const err = new Error(
          `${new Date()} Не удалось получить данные лунного дня для хедера`,
        );
        throw err;
      }

      dispatch({
        type: GET_MOON_DATA,
        data,
      });
    } catch (error) {
      sentryReactSend(error);
      console.error(`${new Date()} ${error}`);
    }
  };

// eslint-disable-next-line @typescript-eslint/default-param-last
const reducer: ReducersTypes<'header'> = (state = initialState, action) => {
  // Деструктурировать на этом уровне нельзя, так как рушится типизация
  switch (action.type) {
    case GET_HAIR_STATUS_DATA:
      return {
        ...state,
        ...action.data,
      };
    // eslint-disable-next-line sonarjs/no-duplicated-branches
    case GET_MOON_DATA:
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
};

// eslint-disable-next-line import/no-default-export
export default reducer;
