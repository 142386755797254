import React from 'react';

import serialize from 'serialize-javascript';

import parser from 'html-react-parser';
import { RawDraftEntity } from 'draft-js';

enum EntityType {
  link = 'link',
  Link = 'Link',
  LINK = 'LINK',
  Divider = 'Divider',
  youtube = 'Youtube',
  lj = 'Livejournal',
  ljVideo = 'LivejournalVideo',
  VideoPlatform = 'VideoPlatform',
  twitter = 'Twitter',
  instagram = 'Instagram',
  facebook = 'Facebook',
  user = 'User',
  image = 'Image',
  telegram = 'Telegram',
  vimeo = 'Vimeo',
  rutube = 'Rutube',
  coub = 'Coub',
  vine = 'Vine',
  R_VIDEO = 'R_VIDEO',
  R_FULLLINK = 'R_FULLLINK',
  R_IMAGE = 'R_IMAGE',
  IMAGE = 'IMAGE',
}

// TODO(HORO-0): размеры эмбедов

/**
 * Мидлвара преобразования entity в html
 *
 * все entity для которых не указано кастомное преобразование,
 * обрабатываются по дефолту пакета draft-convert
 *
 * @param entity - draft entity
 * @param originalText - текст entity
 */
export const entityToHtmlMiddleware = (
  entity: RawDraftEntity,
  originalText: string,
  // eslint-disable-next-line sonarjs/function-return-type
) => {
  const { data, type } = entity;

  let className = 'linkEmbed';

  if (type === EntityType.R_FULLLINK) {
    className += ' full-read-link';
  }

  let imageData = {};

  if (type === EntityType.R_IMAGE) {
    imageData = {
      url: data.image.url,
      alt: '',
      width: null,
      height: null,
      sourceText: '',
      sourceUrl: '',
    };
  } else {
    // type === EntityType.IMAGE
    imageData = {
      url: data.src,
      alt: data.alt,
      width: null,
      height: null,
      sourceText: '',
      sourceUrl: '',
    };
  }

  switch (type) {
    case EntityType.link:
    case EntityType.Link:
    case EntityType.LINK:
    case EntityType.R_FULLLINK:
      return (
        <a
          href={data.url || data.href}
          className={className}
          target="_blank"
          rel="noreferrer noopener"
        >
          {originalText}
        </a>
      );

    case EntityType.Divider: {
      return <hr className="divider" />;
    }

    case EntityType.youtube: {
      return (
        <div className="videoContainer">
          <iframe
            title="Youtube video"
            className="youtubeEmbed"
            src={`https://www.youtube.com/embed/${data.id}`}
            width="740"
            height="416"
            frameBorder="0"
            allowFullScreen
            data-link={`https://youtube.com/watch?v=${data.id}`}
          />
        </div>
      );
    }

    case EntityType.lj: {
      return (
        <iframe
          title="LJ material"
          src={`${data.link}?embed`}
          className="ljMaterialEmbed"
          width="502"
          height="612"
          style={{ maxWidth: '100%' }}
          frameBorder="0"
        />
      );
    }

    case EntityType.VideoPlatform: {
      return (
        <div
          className="videoPlatformEmbed"
          data-widget="Player"
          data-id={data.id}
        />
      );
    }

    case EntityType.ljVideo: {
      return (
        <div
          className="ljVideoEmbed"
          data-widget="Player"
          data-id={data.id}
          id={`video-player-${data.id}`}
        />
      );
    }

    case EntityType.twitter: {
      return <div data-embed-type="twitter" data-content={data.content} />;
    }

    case EntityType.instagram: {
      return (
        <iframe
          title="Instagram embed"
          className="instagramEmbed"
          width="540"
          height="900"
          src={`${data.link}embed`}
          frameBorder="0"
        />
      );
    }

    case EntityType.facebook: {
      return (
        <iframe
          title="Facebook embed"
          className="facebookEmbed"
          src={`https://www.facebook.com/plugins/post.php?href=https%3A%2F%2Fwww.facebook.com%2F${data.user}%2Fposts%2F${data.post}%2F&show_text=true&appId`}
          width="100%"
          height="900"
          style={{ border: 'none' }}
          scrolling="no"
          frameBorder="0"
          allowFullScreen
          allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
        />
      );
    }

    case EntityType.user: {
      return (
        <span className="ljUserContainer">
          <a
            href={`https://${data.user}.livejournal.com/`}
            className="ljUserUsername"
            target="_blank"
            rel="noreferrer"
          >
            <strong>{data.displayUsername}</strong>
          </a>
        </span>
      );
    }

    // case EntityType.image: {
    //   return (
    //     <figure
    //       className="figureEmbed"
    //       data-figure-type="image"
    //       data-image-type="standart"
    //     >
    //       <div className={
    //         `figureEmbedImageContainer figureEmbedImageContainer-${data.align || 'standart'}`
    //         }
    //       >
    //         <img
    //           style={{ maxWidth: '100%' }}
    //           src={data.src}
    //           srcSet={`${data.src} 800w, ${data.src}1920w`}
    //           sizes="800px"
    //           className="figureEmbedImage"
    //           alt={data.alt}
    //           title={data.alt}
    //           loading="lazy"
    //         />
    //         <figcaption className="figureEmbedFigcaption">{data.alt}</figcaption>
    //       </div>
    //     </figure>
    //   );
    // }

    case EntityType.R_IMAGE:
    case EntityType.IMAGE:
      return (
        <div
          data-block-type="image"
          data-block-image-data={serialize(imageData, { isJSON: true })}
        />
      );

    // TODO(HORO-0): править, внешний вид не тот что нужно
    case EntityType.telegram: {
      const dataAttr = {
        async: true,
        src: 'https://telegram.org/js/telegram-widget.js?14',
        'data-telegramPost': `${data.user}/${data.post}`,
        'data-width': '100%',
      };

      return (
        <div data-embed-type="script" data-fields={JSON.stringify(dataAttr)} />
      );
    }

    case EntityType.vimeo: {
      return (
        <div className="videoContainer">
          <iframe
            title="Vimeo embed"
            src={`https://player.vimeo.com/video/${data.id}`}
            width="740"
            height="417"
            frameBorder="0"
            allowFullScreen
            data-link={`https://player.vimeo.com/video/${data.id}`}
          />
        </div>
      );
    }

    case EntityType.rutube: {
      return (
        <div className="videoContainer">
          <iframe
            title="rutube video"
            src={`https://rutube.ru/play/embed/${data.id}/`}
            width="740"
            height="417"
            frameBorder="0"
            allowFullScreen
            data-link={data.link}
          />
        </div>
      );
    }

    case EntityType.coub: {
      return (
        <div className="videoContainer">
          <iframe
            title="Coub video"
            src={data.link}
            allowFullScreen
            frameBorder="0"
            width="640"
            height="272"
          />
        </div>
      );
    }

    case EntityType.vine: {
      return (
        <div className="videoContainer">
          <iframe
            title="Vine video"
            src={`https://vine.co/v/${data.id}/embed/simple`}
            width="380"
            height="380"
            frameBorder="0"
            data-link={`https://vine.co/v/${data.id}/embed/simple`}
          />
        </div>
      );
    }

    case EntityType.R_VIDEO: {
      const { video } = data;
      const code = parser(video.htmlCode);

      return (
        <div className="video-wrapper">
          <div>{code}</div>
          {video.annotation && video.annotation.length ? (
            <div className="video-annotation">{video.annotation}</div>
          ) : (
            ''
          )}
        </div>
      );
    }

    default:
      return originalText;
  }
};
