const FORMAT = 'img-format=auto';
const FILTER = 'img-1-filter=sharpen';

/**
 * Генерация урла на img.rl0.ru (сервис кропа изображения рамблера)
 * или S3
 * для изменения размеров изображения
 *
 * @param src - урл картинки
 * @param origin - Протокол, хост и порт текущего URL
 * @param width - неободимая ширина картинки
 * @param height - необходимая высота картинки
 */
export const cropImage = ({
  src,
  origin,
  width,
  height,
}: {
  src: string;
  origin: string;
  width: number;
  height: number;
}) => {
  const getS3ResizeUrl = () => {
    const url = new URL(src);
    const urlStringified = url.origin + url.pathname;

    const readyUrl = `${urlStringified}?${FORMAT}&${FILTER}`;

    if (width || height) {
      let widthSize = `width:${width},`;
      let heightSize = `height:${height},`;
      let fit = 'cover';

      if (!width) {
        widthSize = '';
        fit = 'max';
      }

      if (!height) {
        heightSize = '';
        fit = 'max';
      }

      return `${readyUrl}&img-2-resize=${widthSize}${heightSize}fit:${fit}`;
    }

    return readyUrl;
  };

  if (!src) {
    return `${origin}/ts-special/no_img.jpg`;
  }

  return getS3ResizeUrl();
};
