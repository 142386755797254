/* eslint-disable @typescript-eslint/no-magic-numbers */
/* eslint-disable security/detect-non-literal-regexp */
import { projectLinks, ProjectLinkType } from 'config/constants/projectLinks';
import { zodiacSigns } from 'config/constants/routes';
import { signNames } from 'config/constants/signNames';

export const isActive = (
  currentBurgerSection: string,
  codeStr?: string | RegExp,
) =>
  codeStr
    ? currentBurgerSection === codeStr ||
      new RegExp(codeStr).test(currentBurgerSection)
    : false;

const mappedChildren = ({
  currentBurgerSection,
  item,
  isMobile,
  zodiacSign,
  chineseSign,
  isHoroscopes,
  isZodiac,
  isChinese,
  isCalendar,
}: {
  currentBurgerSection: string;
  item: ProjectLinkType;
  isMobile: boolean;
  zodiacSign?: string;
  chineseSign?: string;
  isHoroscopes?: boolean;
  isZodiac?: boolean;
  isChinese?: boolean;
  isCalendar?: boolean;
}): ProjectLinkType[] => {
  const itemChildren = [...(item.children || [])];
  if (!itemChildren || !itemChildren.length) return [];

  if (isHoroscopes && zodiacSign) {
    itemChildren[5].href = '/man/today/';
    itemChildren.pop();

    itemChildren.push(
      {
        href: '/sovmestimost-znakov-zodiaka/',
        title: 'Совместимость',
        active: false,
        children: [],
      },
      {
        href: '/description/',
        title: 'Характеристика',
        active: false,
        children: [],
      },
    );
  } else if (isZodiac && zodiacSign) {
    itemChildren[0].children = [
      {
        title: signNames.zodiac[zodiacSign as keyof typeof SIGN],
        href: `/${zodiacSign}/description/`,
        active: isActive(
          currentBurgerSection,
          new RegExp(`horoscope-description-(${zodiacSigns})$`, 'g'),
        ),
      },
      {
        title: 'Женщина',
        href: `/${zodiacSign}/description/woman/`,
        active: isActive(
          currentBurgerSection,
          new RegExp(`horoscope-description-(${zodiacSigns})/woman$`, 'g'),
        ),
      },
      {
        title: 'Мужчина',
        href: `/${zodiacSign}/description/man/`,
        active: isActive(
          currentBurgerSection,
          new RegExp(`horoscope-description-(${zodiacSigns})/man$`, 'g'),
        ),
      },
      {
        title: 'Ребёнок',
        href: `/${zodiacSign}/description/child/`,
        active: isActive(
          currentBurgerSection,
          new RegExp(`horoscope-description-(${zodiacSigns})/child$`, 'g'),
        ),
      },
      {
        title: 'Камень',
        href: `/znaki-zodiaka/birthstone/${zodiacSign}/`,
        active: isActive(
          currentBurgerSection,
          new RegExp(`horoscope-birthstone-(${zodiacSigns})$`, 'g'),
        ),
      },
    ];

    itemChildren[3] = {
      ...itemChildren[3],
      href: `/${zodiacSign}/description/element/`,
      active: isActive(
        currentBurgerSection,
        /horoscope-description-.+\/element/g,
      ),
    };
  } else if (isChinese && chineseSign && chineseSign !== 'all') {
    itemChildren[0].href = `/chinese/description/${chineseSign}`;
  } else if (
    isCalendar &&
    (isMobile || currentBurgerSection.includes('moon-'))
  ) {
    itemChildren[0].children = [
      {
        title: 'Полнолуние',
        href: '/moon/full-moon/',
        active: isActive(currentBurgerSection, /moon-full|moon-full-.+/g),
      },
      {
        title: 'Новолуние',
        href: '/moon/new-moon/',
        active: isActive(currentBurgerSection, /moon-new|moon-new-.+/g),
      },
      {
        title: 'Фазы луны',
        href: '/moon/day/1/',
        active: isActive(currentBurgerSection, /moon-day-.+/g),
      },
    ];
  }

  const children = itemChildren.map((child: ProjectLinkType) => {
    const { code: codeChild, href: hrefChild, ...restChild } = child;

    let href = hrefChild;

    if (isHoroscopes && zodiacSign) {
      href = `/${zodiacSign}${hrefChild}`;
    } else if (isChinese && chineseSign) {
      href = hrefChild.replace('all', chineseSign);
    }

    const grandChildren = mappedChildren({
      currentBurgerSection,
      item: child,
      isMobile,
      zodiacSign,
      chineseSign,
    });
    const isChildActive =
      isActive(currentBurgerSection, codeChild) || child.active;
    const isGrandChildActive =
      grandChildren &&
      grandChildren.some(
        (grandChild: ProjectLinkType) =>
          isActive(currentBurgerSection, grandChild.code) || grandChild.active,
      );

    return {
      ...restChild,
      href,
      active: isChildActive || isGrandChildActive,
      code: codeChild,
      children: grandChildren,
    };
  });

  return children;
};

export const getTopics = ({
  currentBurgerSection,
  isMobile,
  zodiacSign,
  chineseSign,
}: {
  currentBurgerSection: string;
  isMobile: boolean;
  zodiacSign?: string;
  chineseSign?: string;
}) => {
  const topics: ProjectLinkType[] = projectLinks().map((item) => {
    const { code, ...rest } = item;
    const children: ProjectLinkType[] = mappedChildren({
      currentBurgerSection,
      item,
      isMobile,
      zodiacSign,
      chineseSign,
      isHoroscopes: code === 'horoscope-main',
      isZodiac: code?.toString().includes('horoscope-description'),
      isChinese: code?.toString().includes('chinese-'),
      isCalendar: code === 'horo-calendars',
    });
    const isParentActive =
      children.some((child) => child.active) ||
      isActive(currentBurgerSection, code);

    return {
      ...rest,
      active: isParentActive,
      code,
      children,
    };
  });

  return topics;
};
