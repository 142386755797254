import { OK_STATUS } from 'config/constants/statusCodes';

const GEO_API_URL = 'https://www.rambler.ru/location/current';

export interface GeoDataType {
  id?: string;
  name?: string;
  parents?: string;
  timezone?: string;
  parent?: {
    id?: string;
    name?: string;
  };
}

export const fetchCurrentLocation = async (
  apiUrl = GEO_API_URL,
): Promise<GeoDataType> => {
  try {
    const res: Response = await fetch(apiUrl, { credentials: 'include' });

    if (res.ok === false || res.status !== OK_STATUS) {
      throw new Error(
        `Failed to get geo data (${res.status} ${res.statusText})`,
      );
    }

    const data = await res.json();

    if (data.message) {
      throw new Error(data.message);
    }

    return data || {};
  } catch (error) {
    console.error(`Geo request: ${(error as { message: string }).message}`);

    return {};
  }
};
