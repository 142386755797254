import { RawDraftContentBlock } from 'draft-js';
import React from 'react';

import { generateChpu } from 'utils/generateChpu';
import { signNames } from 'config/constants/signNames';

enum BlockType {
  atomic = 'atomic',
  codeBlock = 'code-block',
  adv = 'adv',
  author = 'author',
  h2 = 'header-two',
  h3 = 'header-three',
}

/**
 * Мидлвара преобразования блоков драфта в html
 *
 * все блоки для которых не указано кастомное преобразование,
 * обрабатываются по дефолту пакета draft-convert
 *
 * @param block - draft block
 */
export const blockToHtmlMiddleware = (block: RawDraftContentBlock) => {
  const { type, data, text } = block;

  switch (type) {
    case BlockType.atomic: {
      if (data?.type === 'image') {
        return <img src={data.src} alt="main_image" width={data.width} />;
      }

      return null;
    }

    case BlockType.codeBlock: {
      return <div data-block-type="code">{text}</div>;
    }

    case BlockType.adv: {
      return <div data-block-type="adv" data-block-adv-name={text} />;
    }

    case BlockType.author: {
      if (text) {
        return <span className="draftAuthor">{text}</span>;
      }

      return <span className="empty" />;
    }

    case BlockType.h2: {
      return (
        <h2 {...(data?.withAnchors && { id: generateChpu(text) })}>{text}</h2>
      );
    }

    case BlockType.h3: {
      const isSignTitle = Object.values(signNames.zodiac).some(
        (signTitle: string) => text.includes(signTitle),
      );

      // https://jira.rambler-co.ru/browse/HORO-5916
      // на все заголовки h3 с текстом содержащий название знака зодиака
      // цепляются id со значением name знака
      if (isSignTitle) {
        const singName = Object.keys(signNames.zodiac).find(
          (name: keyof typeof signNames.zodiac) =>
            text.includes(signNames.zodiac[name]),
        );

        return <h3 id={singName || ''}>{text}</h3>;
      }

      return <h3>{text}</h3>;
    }

    default:
      return null;
  }
};
