import { useCallback, useEffect, useRef } from 'react';
import { safeGet } from 'utils/safeGet';
import { checkIsEagleplatform } from 'common/utils/checkIsEagleplatform';

/**
 * Удаление класса(--loading) с плейсхолдером для фото и видео в тексте кластера после их загрузки
 * @param html - текст параграфа
 */
export const useLoadedPlaceholder = <El extends HTMLElement>(html: string) => {
  const ref = useRef<El>(null);
  const imageNode = safeGet(
    () => ref.current?.getElementsByTagName('img')[0],
    null,
  );
  const videoNode = safeGet(
    () => ref.current?.getElementsByTagName('iframe')[0],
    null,
  );

  let removeImageLoadClass: () => void;

  const removeLoadClass = useCallback(
    (isImage = false) => {
      const divWrapperNode = ref.current?.firstElementChild;

      if (divWrapperNode) {
        divWrapperNode.className = divWrapperNode?.className.replace(
          '--loading',
          '',
        );
      }

      if (isImage) {
        imageNode?.removeEventListener('load', removeImageLoadClass);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [imageNode],
  );

  removeImageLoadClass = () => removeLoadClass(true);

  const listener = useCallback((event: any) => {
    if (event.data.event === 'ready') {
      removeLoadClass();
      window.removeEventListener('message', listener);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (html && html.includes('--loading')) {
      const videoEagleplatform =
        videoNode && checkIsEagleplatform(videoNode.src);

      if (imageNode) {
        imageNode.addEventListener('load', removeImageLoadClass);
      }

      if (videoEagleplatform) {
        window.addEventListener('message', listener);
      }

      removeLoadClass();
    }

    return undefined;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return ref;
};
