import isEmpty from 'lodash.isempty';

import fetch from 'common/utils/fetch';
import { sentryReactSend } from 'utils/sentry/client';
import { API } from 'config/constants/api';

import type { AppStore } from './configure';

export const GET_TAROT_DATA = 'GET_TAROT_DATA';

const initialState: TarotType = {
  today: {},
};

export const fetchTarotData =
  (fetchName: keyof APIsUrls['tarotBlocks']) =>
  async (dispatch: AppStore['dispatch'], getState: AppStore['getState']) => {
    try {
      const tarotData = getState().tarot[fetchName];
      if (!isEmpty(tarotData)) return;

      const url = `fortune/${API.tarotBlocks[fetchName]}`;
      const data = await fetch({
        path: url,
        state: getState(),
        apiVersion: 'v4',
      });

      if (!data) {
        const err = new Error(
          `${new Date()} Не удалось получить данные блока таро: ${url}`,
        );
        throw err;
      }

      dispatch({
        type: GET_TAROT_DATA,
        name: fetchName,
        data,
      });
    } catch (error) {
      sentryReactSend(error);
      console.error(`${new Date()} ${error}`);
    }
  };

// eslint-disable-next-line @typescript-eslint/default-param-last
const reducer: ReducersTypes<'tarot'> = (state = initialState, action) => {
  if (action.type === GET_TAROT_DATA) {
    return {
      ...state,
      [action.name]: action.data,
    };
  }

  return state;
};

// eslint-disable-next-line import/no-default-export
export default reducer;
