import React from 'react';

import {
  checkIframeInText,
  changeVideoIframe,
} from 'common/utils/searchIframe';
import { checkIsEagleplatform } from 'common/utils/checkIsEagleplatform';

import { useLoadedPlaceholder } from './useLoadedPlaceholder';

interface PropsType {
  text: string;
  className: string;
  puids?: PuidsType;
  isMediaContent?: boolean;
}

/**
 * Параграф с текстом
 *
 * @param text - текст параграфа
 * @param disableAdv - флаг отключения рекламы
 * @param isMediaContent - флаг, что параграф содержит фото или видео
 */
export const TextParagraph: React.FC<PropsType> = React.memo(
  ({ text, className, puids, isMediaContent }) => {
    let html: string = text;

    if (checkIframeInText(text) && checkIsEagleplatform(text)) {
      html = changeVideoIframe(text, '', puids || { puid6: '' });
    }

    const ref = useLoadedPlaceholder<HTMLDivElement & HTMLParagraphElement>(
      html,
    );

    const startFromTag = /^(<.+?>)/g;

    if (startFromTag.test(html)) {
      return (
        <div
          className={className}
          {...(isMediaContent ? { ref } : {})}
          suppressHydrationWarning
          dangerouslySetInnerHTML={{ __html: html }}
        />
      );
    }

    return (
      <p
        className={className}
        {...(isMediaContent ? { ref } : {})}
        suppressHydrationWarning
        dangerouslySetInnerHTML={{ __html: html }}
      />
    );
  },
  () => true,
);

TextParagraph.displayName = 'TextParagraph';
