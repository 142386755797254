import { combineReducers } from 'redux';

import runtime from 'common/redux/runtime';
import error from 'common/redux/error';
import meta from 'common/redux/meta';
import pages from 'common/redux/pages';
import account from 'common/redux/account';
import compatibility from 'common/redux/compatibility';
import likes from 'common/redux/likes';
import fortune from 'common/redux/fortune';
import chinese from 'common/redux/chinese';
import calendar from 'common/redux/calendar';
import humanDesign from 'common/redux/humanDesign';
import header from 'common/redux/header';
import tarot from 'common/redux/tarot';
import emojiWidget from 'common/redux/emojiWidget';
import vkVideoWidget from 'common/redux/vkVideoWidget';

// eslint-disable-next-line import/no-default-export
export default combineReducers({
  runtime,
  error,
  meta,
  pages,
  account,
  compatibility,
  likes,
  fortune,
  chinese,
  calendar,
  humanDesign,
  header,
  tarot,
  emojiWidget,
  vkVideoWidget,
});
