import format from 'date-fns/format';
import fetch from 'common/utils/fetch';
import { sentryReactSend } from 'utils/sentry/client';

import type { AppStore } from './configure';

export const GET_EMOJI_WIDGET_DATA = 'GET_EMOJI_WIDGET_DATA';

const initialState: EmoWidgetType = {
  data: {},
};

export const fetchEmoWidgetData =
  ({ sign }: { sign: string }) =>
  async (dispatch: AppStore['dispatch'], getState: AppStore['getState']) => {
    try {
      if (!sign) {
        const err = new Error('Нужно передать знак зодиака для эмо-гороскопа');
        throw err;
      }

      const date = format(new Date(), 'yyyy-MM-dd');
      const url = `horoscopes/emotional/?sign=${sign}&date=${date}`;

      const data = await fetch({
        path: url,
        state: getState(),
        apiVersion: 'v4',
      });

      if (data.error) {
        const err = new Error(
          'Не удалось получить данные эмоционального гороскопа',
        );
        throw err;
      }

      dispatch({
        type: GET_EMOJI_WIDGET_DATA,
        data: {
          ...data,
          sign,
          date,
        },
      });
    } catch (error) {
      sentryReactSend(error);
      console.error(`${new Date()} ${error}`);
    }
  };

const reducer: ReducersTypes<'emojiWidget'> = (
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state = initialState,
  action,
) => {
  if (action.type === GET_EMOJI_WIDGET_DATA) {
    return {
      ...state,
      data: action.data,
    };
  }

  return state;
};

// eslint-disable-next-line import/no-default-export
export default reducer;
